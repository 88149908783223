<template>
  <header class="header dpo">
    <div class="header__wrap">
      <div class="header__logoContainer">
        <a href="/">
          <svg class="header__logo">
            <use xlink:href="#nii-logo--sprite" />
          </svg>
        </a>
      </div>

      <div class="header__content">
        <div class="header__navList">
          <nav
            class="header__nav"
            v-for="(navElem, i) in menu"
            :key="navElem.title"
          >
            <router-link
              class="header__link"
              :to="{
                name: navElem.link,
              }"
            >
              {{ navElem.title }}
            </router-link>
            <div class="header__line" v-if="i !== menu.length - 1" />
          </nav>
        </div>

        <div class="header__personContainer">
          <div class="header__socials">
            <a
              class="header__socialLink"
              href="https://vk.com/niiozmm"
              target="_blank"
            >
              <svg class="header__socialIcon">
                <use xlink:href="#vk-green-icon--sprite" />
              </svg>
            </a>
            <a
              class="header__socialLink"
              href="https://www.youtube.com/c/%D0%9D%D0%98%D0%98%D0%9E%D0%97%D0%9C%D0%9C"
              target="_blank"
            >
              <svg class="header__socialIcon">
                <use xlink:href="#yt-green-icon--sprite" />
              </svg>
            </a>
            <a
              class="header__socialLink"
              href="https://t.me/mmcito"
              target="_blank"
            >
              <svg class="header__socialIcon">
                <use xlink:href="#tg-green-icon--sprite" />
              </svg>
            </a>
            <a
              class="header__socialLink"
              href="https://rutube.ru/channel/30325843/"
              target="_blank"
            >
              <svg class="header__socialIcon">
                <use xlink:href="#rutube-green-icon--sprite" />
              </svg>
            </a>
          </div>

          <!-- <CommonButtonPrimary @click="goToNiioz" v-if="!userFullName"> -->
          <CommonButtonPrimary v-if="isDpoPage" @click="goToNiioz">
            Войти
          </CommonButtonPrimary>

          <!-- <div class="header__person bold-text" v-else>{{ userFullName }}</div> -->
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { useVfm } from "vue-final-modal";
import { RouteName } from "@/constants";
import { menu } from "./constants";
import { useRoute } from "vue-router";
import store from "@/store";

import "@/assets/img/icons/logo-icon.svg?sprite";
import "@/assets/img/icons/message-icon.svg?sprite";
import "@/assets/img/icons/bell-icon.svg?sprite";
import "@/assets/img/icons/nii-logo.svg?sprite";
import "@/assets/img/icons/vk-green-icon.svg?sprite";
import "@/assets/img/icons/yt-green-icon.svg?sprite";
import "@/assets/img/icons/tg-green-icon.svg?sprite";
import "@/assets/img/icons/rutube-green-icon.svg?sprite";

export default defineComponent({
  name: "HomeHeader",
  components: {
    CommonButtonPrimary,
  },

  setup() {
    const route = useRoute();

    const vfm = useVfm();

    const me = computed(() => store.getters.userStore.me);

    const userFullName = computed(() => me.value?.fullname);

    const isShownNotifications = ref(false);

    const isHomeMain = computed(() => route?.name === RouteName.HOME);

    const isDpoPage = computed(
      () =>
        (route?.name === RouteName.COURSES_DPO &&
          (!route?.params?.id || +route?.params?.id === 4)) ||
        route?.name === RouteName.HOME_COURSES ||
        route?.name === RouteName.HOME_COURSES_ADMISSION ||
        route?.name === RouteName.HOME_COURSES_CERT ||
        route?.name === RouteName.HOME_COURSES_FAQ ||
        route?.name === RouteName.HOME_COURSES_PLAN ||
        route?.name === RouteName.HOME_COURSES_REVIEWS
    );

    const openSignInModal = () => vfm.open("signInModal");
    const goToNiioz = () =>
      window.location.replace("https://onlinelearning.niioz.ru/login");

    onMounted(() => {
      store.dispatch.userStore.getMe();
    });

    return {
      userFullName,
      isHomeMain,
      menu,
      RouteName,
      isShownNotifications,
      isDpoPage,
      openSignInModal,
      goToNiioz,
    };
  },
});
</script>

<style lang="stylus" scoped>
.header {
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: $color-white;

  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 20px;
  }

  &__logo {
    width: 130px;
    height: 54px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1vw;
    flex-grow: 1;
  }

  &__navList {
    display: none;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  &__iconsContainer {
    display: flex;
    align-items: center;
    gap: .6vw;
  }

  &__icon {
    width: 20px;
    height: 20px;

    &:hover {
      opacity: .6;
      cursor: pointer;
    }
  }

  &__link {
    color: $dpo-black;
    text-decoration: none;
    font-size: 16px;
  }

  &__line {
    width: 1px;
    height: 20px;
    background-color: #C1C1C1;
    margin: 0 20px;
  }
  &__nav {
    display: flex;
  }

  &__socials {
    display: flex;
    gap: 5px;
  }

  &__socialIcon {
    width: 32px;
    height: 32px;
  }

  &__personContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    flex-grow: 1;
    justify-content: flex-end;
  }

  &__person {
    font-weight: bold;
  }

  +mediaTablet() {
    padding: 20px;

    &__logo {
      width: 15vw;
    }

    &__icon {
      width: 30px;
      height: 30px;
    }

    &__navList {
      display: flex;
    }
  }

  +mediaGiant() {
    &__link {
      font-size: 20px;
    }
  }

  @media (min-width:1400px) {
    &__wrap {
      max-width: 1400px;
    }
  }
}
</style>
