<template>
  <div class="commonFormContainer">
    <h5 class="commonFormContainer__title">{{ title }}</h5>

    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

import "@/assets/img/icons/arrow-right-icon.svg?sprite";

export default defineComponent({
  name: "CommonFormContainer",

  props: {
    title: {
      type: String,
    },
  },

  emits: [],

  components: {},

  setup() {
    return {};
  },
});
</script>

<style lang="stylus" scoped>
.commonFormContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  background-color: $color-white;
  border-radius: 10px;
  border: 1px solid $magistracy-medium-grey;

  &__title {
    padding-bottom: 4px;
    getFontSmall();
    font-weight: 500;
    border-bottom: 1px solid $magistracy-medium-grey;
  }
}
</style>
