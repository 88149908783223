<template>
  <button
    :class="[
      'commonDropdownActions',
      { commonDropdownActions_active: hasOpenDropdown },
    ]"
    @click="toggleDropdown"
  >
    <svg
      :class="[
        'commonDropdownActions__menuIcon',
        {
          commonDropdownActions__menuIcon_dark: !hasOpenDropdown,
          commonDropdownActions__menuIcon_active: hasOpenDropdown,
        },
      ]"
    >
      <use :href="`#menu-icon--sprite`" />
    </svg>

    <v-menu activator="parent">
      <v-list>
        <v-list-item
          v-for="(item, index) in actionsList"
          :key="index"
          :value="index"
        >
          <button @click="item.action" ref="elemRef">
            <v-list-item-title>
              <div class="commonDropdownActions__elementBox">
                <svg class="commonDropdownActions__elementIcon">
                  <use :href="`#action-icon-${item.id}--sprite`" />
                </svg>

                <span
                  :class="`commonDropdownActions__elementTitle commonDropdownActions__elementTitle-${item.id}`"
                >
                  {{ item.title }}
                </span>
              </div>
            </v-list-item-title>
          </button>
        </v-list-item>
      </v-list>
    </v-menu>
  </button>
</template>

<script lang="ts">
import { defineComponent, defineExpose, ref, computed } from "vue";

import "@/assets/img/icons/menu-icon.svg?sprite";
import "@/assets/img/icons/action-icon-1.svg?sprite";
import "@/assets/img/icons/action-icon-2.svg?sprite";
import "@/assets/img/icons/action-icon-3.svg?sprite";

export default defineComponent({
  name: "CommonDropdownActions",
  components: {},

  emits: ["edit", "archive", "view"],

  props: {},

  setup(_, { emit }) {
    const elemRef = ref<Array<HTMLElement | null>>([]);

    const actionsList = [
      {
        id: 1,
        title: "Редактировать",
        action: () => emit("edit"),
      },
      {
        id: 2,
        title: "Архивировать",
        action: () => emit("archive"),
      },
      {
        id: 3,
        title: "Просмотр",
        action: () => emit("view"),
      },
    ];

    const hasOpenDropdown = computed(() => elemRef.value?.length);

    const toggleDropdown = () => console.log(elemRef.value);

    defineExpose({ elemRef });

    return {
      elemRef,
      actionsList,
      hasOpenDropdown,
      toggleDropdown,
    };
  },
});
</script>

<style scoped lang="stylus">
.commonDropdownActions {
  padding: 4px;
  width: 32px;
  height: 32px;
  background-color: $magistracy-light-grey;
  border-radius: 10px;

  &_active {
    background-color: $as-green-light;
  }

  &__menuIcon {
    width: 100%;
    height: 100%;

    &_dark {
      fill: $as-dark-grey;
    }

    &_active {
      fill: $as-active-green;
    }
  }

  &__elementBox {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__elementIcon {
    width: 16px;
    height: 16px;
  }

  &__elementTitle {
    font-size: 14px;
    font-weight: 500;

    &-1 {
      color: $as-active-green;
    }
    &-2 {
      color: $as-gold;
    }
    &-3 {
      color: $as-blue;
    }
  }
}
</style>
