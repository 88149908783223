<template>
  <VueFinalModal
    :class="{ dpo: hasDpo }"
    v-model="test"
    :name="modalName"
    :modal-id="modalName"
    :title="title"
    :zIndexFn="() => 1000"
  >
    <div class="layoutAuth">
      <div class="layoutAuth__titleBox">
        <svg class="layoutAuth__icon">
          <use xlink:href="#logo-mini-icon--sprite" />
        </svg>
        <slot name="title" />
      </div>

      <div class="layoutAuth__contentBox">
        <slot name="content" />
      </div>

      <div class="layoutAuth__footerBox">
        <slot name="footer" />
      </div>
    </div>
  </VueFinalModal>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { VueFinalModal } from "vue-final-modal";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";

import "@/assets/img/icons/logo-mini-icon.svg?sprite";

export default defineComponent({
  name: "LayoutAuth",

  components: {
    VueFinalModal,
  },

  props: {
    modalName: String,
    title: String,
  },

  setup() {
    const route = useRoute();

    const test = ref<boolean>(false);

    const hasDpo = computed(
      () => route.matched[0]?.name === RouteName.DPO || route.path === "/"
    );

    return {
      test,
      hasDpo,
    };
  },
});
</script>

<style lang="stylus" scoped>
.layoutAuth {
  position: relative;
  top: 60px;
  margin-bottom: 30%;
  width: 100%;
  max-width: 95vw;
  padding: 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  box-shadow: 0 4px 12px 0 rgba(25, 135, 84, 0.25);
  overflow: hidden;
  background-color: $color-white;

  &__icon {
    width: 94px;
    height: 71px;
  }

  &__titleBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    getFontH4();
    color: $as-green-magistr;
    text-wrap: wrap;
  }

  &__contentBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 13px;
  }

  &__footerBox:empty {
    display: none;
  }

  +mediaTablet() {
    max-width: 40vw;
    padding: 64px;
    gap: 3vw;
  }
}

.dpo .layoutAuth {
  &__titleBox {
    color: $as-green-magistrDpo;
  }
}
</style>
