import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-10e0bc52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "commonPagination" }
const _hoisted_2 = { class: "commonPagination__arrowsBox" }
const _hoisted_3 = { class: "commonPagination__countBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock("svg", {
        class: _normalizeClass([
          'arrow-wrap',
          'arrow_left',
          { 'arrow-wrap__active': _ctx.isActiveArrowLeft },
        ]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToPrevPage && _ctx.goToPrevPage(...args)))
      }, [
        _createElementVNode("use", {
          class: _normalizeClass(['arrow', { arrow__active: _ctx.isActiveArrowLeft }]),
          "xlink:href": "#arrow-right-icon--sprite"
        }, null, 2)
      ], 2)),
      (_openBlock(), _createElementBlock("svg", {
        class: _normalizeClass(['arrow-wrap', { 'arrow-wrap__active': _ctx.isActiveArrowRight }]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToNextPage && _ctx.goToNextPage(...args)))
      }, [
        _createElementVNode("use", {
          class: _normalizeClass(['arrow', { arrow__active: _ctx.isActiveArrowRight }]),
          "xlink:href": "#arrow-right-icon--sprite"
        }, null, 2)
      ], 2))
    ]),
    _createElementVNode("div", _hoisted_3, " Страница " + _toDisplayString(_ctx.currentPage) + " из " + _toDisplayString(_ctx.length), 1)
  ]))
}