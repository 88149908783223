<template>
  <CommonFormContainer title="Документы">
    <div
      class="programs-input-box"
      v-for="(item, i) in formModel"
      :key="`how-learn-going-${i}`"
    >
      <CommonInput
        v-model="formModel[i].title"
        placeholder="Название"
        @update:model-value="$emit('updateValues', formModel)"
      >
        Название
      </CommonInput>

      <span class="programs-label"> Список </span>

      <div class="programs-input-box">
        <CommonInput
          v-for="(item, index) in formModel[i].list"
          :key="`goal-text-${index}`"
          v-model="formModel[i].list[index]"
          placeholder="Введите текст"
          @update:model-value="$emit('updateValues', formModel)"
        >
          <template #suffix>
            <svg
              class="programs-close"
              @click="
                removeItem(formModel[i].list, index);
                $emit('updateValues', formModel);
              "
            >
              <use xlink:href="#close-icon--sprite" />
            </svg>
          </template>
        </CommonInput>

        <svg
          class="programs-plus"
          @click="
            pushNewItem(formModel[i].list, '');
            $emit('updateValues', formModel);
          "
        >
          <use xlink:href="#plus-curator--sprite" />
        </svg>
      </div>

      <div
        class="programs-delete"
        @click="
          $emit('updateValues', formModel);
          removeItem(formModel, i);
        "
      >
        Удалить
      </div>
    </div>

    <svg
      class="programs-plus"
      @click="
        pushNewItem(formModel, {
          title: '',
          list: [],
        });
        $emit('updateValues', formModel);
      "
    >
      <use xlink:href="#plus-curator--sprite" />
    </svg>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";

export default defineComponent({
  name: "FormDocs",

  components: {
    CommonFormContainer,
    CommonInput,
  },

  props: ["form"],

  setup(props) {
    const formModel = reactive([
      {
        title: "",
        list: [],
      },
    ]);

    const pushNewItem = (item: any, content: any) => {
      item.push(content);
    };

    const removeItem = (item: any, index: number) => {
      item.splice(index, 1);
    };

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
      pushNewItem,
      removeItem,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
