import { RouteName } from "@/constants";

export const breadcrumbsItems = [
  {
    title: "Программы",
    to: {
      name: RouteName.CURATOR_PROGRAMS,
    },
  },
  {
    title: "Архив программ",
    to: {
      name: RouteName.CURATOR_PROGRAMS_ARCHIVE,
    },
  },
];
