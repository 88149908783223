<template>
  <div class="commonComponentHeader">
    <div class="commonComponentHeader__titleBox">
      <CommonBreadcrumbs :items="items" v-if="items" />

      <div class="commonComponentHeader__title">{{ title }}</div>
    </div>

    <div class="commonComponentHeader__filtersBox">
      <slot name="filter-box" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { CommonBreadcrumbs } from "../CommonBreadcrumbs";

import "@/assets/img/icons/arrow-breadcrumb-icon.svg?sprite";

export default defineComponent({
  name: "CommonComponentHeader",

  props: ["items", "title"],

  components: {
    CommonBreadcrumbs,
  },

  setup() {
    return {};
  },
});
</script>

<style lang="stylus" scoped>
.commonComponentHeader {
  &__titleBox {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: $color-white;
    border-bottom: 1px solid $magistracy-medium-grey;
  }

  &__title {
    font-size: 32px;
  }
}
</style>
