<template>
  <CommonFormContainer title="Сертификаты">
    <CommonInput
      v-model="formModel.desc"
      placeholder="Описание"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Описание
    </CommonInput>

    <CommonSelect
      v-model="formModel.type"
      :options="['1', '2', '3']"
      placeholder="Выберите из списка"
      :multiple="false"
    >
      Тип
    </CommonSelect>

    <CommonInput
      v-model="formModel.title"
      placeholder="Название"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Название
    </CommonInput>

    <span class="programs-label"> Список </span>

    <div class="programs-input-box">
      <CommonInput
        v-for="(item, i) in formModel.list"
        :key="`goal-text-${i}`"
        v-model="formModel.list[i]"
        placeholder="Введите текст"
        @update:model-value="$emit('updateValues', formModel)"
      >
        <template #suffix>
          <svg
            class="programs-close"
            @click="
              removeItem(formModel.list, i);
              $emit('updateValues', formModel);
            "
          >
            <use xlink:href="#close-icon--sprite" />
          </svg>
        </template>
      </CommonInput>

      <svg
        class="programs-plus"
        @click="
          pushNewItem(formModel.list);
          $emit('updateValues', formModel);
        "
      >
        <use xlink:href="#plus-curator--sprite" />
      </svg>
    </div>

    <CommonInput
      v-model="formModel.details"
      @update:model-value="$emit('updateValues', formModel)"
      placeholder="Аннотация"
    >
      Аннотация
    </CommonInput>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";

export default defineComponent({
  name: "FormCertificates",

  components: {
    CommonFormContainer,
    CommonInput,
    CommonSelect,
  },

  props: ["form"],

  setup(props) {
    const formModel = reactive({
      desc: "",
      type: "",
      title: "",
      list: [""],
      details: "",
    });

    const pushNewItem = (item: any) => {
      item.push("");
    };

    const removeItem = (item: any, index: number) => {
      item.splice(index, 1);
    };

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
      pushNewItem,
      removeItem,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
