<template>
  <div class="notificationsContainer">
    <h6 class="notificationsContainer__title">Уведомления</h6>

    <div class="notificationsContainer__list">
      <div
        class="notificationsContainer__item"
        v-for="(item, i) in notificationsList"
        :key="`notification-${i}`"
      >
        {{ item }}
      </div>

      <CommonButtonPrimary class="notificationsContainer__btn">
        Все уведомления
      </CommonButtonPrimary>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { notificationsList } from "./constants";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";

export default defineComponent({
  name: "NotificationsContainer",

  components: {
    CommonButtonPrimary,
  },

  setup() {
    return {
      notificationsList,
    };
  },
});
</script>

<style lang="stylus" scoped>
.notificationsContainer {
  width: 520px;
  max-width: 27vw;
  background-color: $color-white;
  border: 1px solid $magistracy-medium-grey;
  border-radius: 10px;

  &__title {
    padding: 20px 24px;
    border-bottom: 1px solid $magistracy-medium-grey;
  }

  &__list {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    padding-bottom: 8px;
    border-bottom: 1px solid $magistracy-medium-grey;
  }

  &__btn {
    width: 100%;
  }
}
</style>
