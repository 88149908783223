<template>
  <CommonFormContainer title="Контакты">
    <CommonInput
      v-model="formModel.manager.fullname"
      placeholder="ФИО"
      @update:model-value="$emit('updateValues', formModel)"
    >
      ФИО
    </CommonInput>

    <CommonInput
      v-model="formModel.manager.desc"
      placeholder="Должность"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Должность
    </CommonInput>

    <CommonInput
      v-model="formModel.works_time"
      placeholder="Рабочее время"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Рабочее время
    </CommonInput>

    <span class="programs-label"> Почта </span>

    <div class="programs-input-box">
      <CommonInput
        v-for="(item, index) in formModel.emails"
        :key="`goal-text-${index}`"
        v-model="formModel.emails[index]"
        placeholder="Введите текст"
        @update:model-value="$emit('updateValues', formModel)"
      >
        <template #suffix>
          <svg
            class="programs-close"
            @click="
              removeItem(formModel.emails, index);
              $emit('updateValues', formModel);
            "
          >
            <use xlink:href="#close-icon--sprite" />
          </svg>
        </template>
      </CommonInput>

      <svg
        class="programs-plus"
        @click="
          pushNewItem(formModel.emails, '');
          $emit('updateValues', formModel);
        "
      >
        <use xlink:href="#plus-curator--sprite" />
      </svg>
    </div>

    <span class="programs-label"> Телефон </span>

    <div class="programs-input-box">
      <CommonInput
        v-for="(item, index) in formModel.phones"
        :key="`goal-text-${index}`"
        v-model="formModel.phones[index]"
        placeholder="Введите текст"
        @update:model-value="$emit('updateValues', formModel)"
      >
        <template #suffix>
          <svg
            class="programs-close"
            @click="
              removeItem(formModel.phones, index);
              $emit('updateValues', formModel);
            "
          >
            <use xlink:href="#close-icon--sprite" />
          </svg>
        </template>
      </CommonInput>

      <svg
        class="programs-plus"
        @click="
          pushNewItem(formModel.phones, '');
          $emit('updateValues', formModel);
        "
      >
        <use xlink:href="#plus-curator--sprite" />
      </svg>
    </div>

    <CommonInput
      v-model="formModel.address"
      placeholder="Адрес"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Адрес
    </CommonInput>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";

export default defineComponent({
  name: "FormContacts",

  components: {
    CommonFormContainer,
    CommonInput,
  },

  props: ["form"],

  setup(props) {
    const formModel = reactive({
      manager: {
        fullname: "",
        desc: "",
      },
      works_time: "",
      emails: [],
      phones: [],
      address: "",
    });

    const pushNewItem = (item: any, content: any) => {
      item.push(content);
    };

    const removeItem = (item: any, index: number) => {
      item.splice(index, 1);
    };

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
      pushNewItem,
      removeItem,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
