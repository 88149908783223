<template>
  <LayoutBase>
    <div v-if="hasProgramsListPage">
      <CommonComponentHeader title="Программы">
        <template #filter-box>
          <div class="filtersBox">
            <CommonInput backgroundColor="#fff" style="max-width: 620px">
              <template #prefix>
                <svg class="searchIcon">
                  <use xlink:href="#search-icon--sprite" />
                </svg>
              </template>
            </CommonInput>

            <CommonButton colorType="green-light" @click="goToCreateProgram">
              Новая программа
            </CommonButton>
          </div>
        </template>
      </CommonComponentHeader>

      <CommonTable
        :head-list="listHeader"
        :body-list="mappedBody"
        @edit="handleEdit"
        @archive="handleArchive"
      />

      <CommonPagination
        v-if="hasShowPagination"
        :length="pagesCount"
        :current-page="page"
        @changePage="changePage"
        class="programsPage__pagination"
      />
    </div>

    <router-view v-else />
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from "vue";
import { CommonTable } from "@/components/NewComponents/CommonTable";
import { LayoutBase } from "@/layouts/NewLayouts/LayoutBase";
import { CommonPagination } from "@/components/NewComponents/CommonPagination";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";
import store from "@/store";
import { listHeader, listBody, statuses } from "./constants";

export default defineComponent({
  name: "ProgramsPage",

  components: {
    LayoutBase,
    CommonTable,
    CommonPagination,
    CommonComponentHeader,
    CommonInput,
    CommonButton,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const page = ref<number>(1);

    const pagesCount = computed(() =>
      Math.ceil((Number(degreeList.value?.length) || 0) / 10)
    );

    const hasShowPagination = computed(() => pagesCount.value > 1);

    const degreeList = computed(() => store.getters.programsStore?.listData);

    const mappedBody = computed(() =>
      degreeList.value?.map((item: any) => {
        return [
          {
            title: item.title,
          },
          {
            title: item.start_at?.slice(0, 10),
          },
          {
            title: item.end_at?.slice(0, 10),
          },
          {
            title: "3",
          },
          {
            title: item.teachers?.join(", "),
          },
          {
            title: statuses.active.title,
            hasStatus: true,
            statusColor: statuses.active.color,
          },
          {
            title: "active",
            hasAction: true,
            id: item.id,
          },
        ];
      })
    );

    const hasProgramsListPage = computed(
      () => route.name === RouteName.CURATOR_PROGRAMS
    );

    const changePage = (value: number) => {
      page.value = value;
    };

    const handleEdit = (programId: number) => {
      router.push({
        name: RouteName.CURATOR_PROGRAMS_EDIT,
        query: { programId: programId },
      });
    };

    const handleArchive = () => {
      router.push({ name: RouteName.CURATOR_PROGRAMS_ARCHIVE });
    };

    const goToCreateProgram = () =>
      router.push({
        name: RouteName.CURATOR_PROGRAMS_CREATE,
      });

    onMounted(async () => {
      await store.dispatch.programsStore.getDegreeList({
        degree_id: 4,
        pageNumber: ++page.value,
      });
    });

    return {
      pagesCount,
      mappedBody,
      page,
      hasProgramsListPage,
      listHeader,
      listBody,
      degreeList,
      hasShowPagination,
      goToCreateProgram,
      changePage,
      handleEdit,
      handleArchive,
    };
  },
});
</script>

<style lang="stylus" scoped>
.programsPage {
  &__pagination {
    border-top: 1px solid $magistracy-medium-grey;
  }
}
.searchIcon {
  width: 16px;
  height: 16px;
}

.filtersBox {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
</style>
