import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-83baba6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "side" }
const _hoisted_2 = { class: "side__wrap" }
const _hoisted_3 = { class: "side__navigationBox" }
const _hoisted_4 = {
  key: 0,
  class: "side__icon"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 1,
  class: "side__title"
}
const _hoisted_7 = { class: "side__icon" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "side__actionsBox" }
const _hoisted_10 = { class: "side__icon" }
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "side__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navList, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(['side__item', { side__itemExpansion: !item.pathName }]),
            key: item.id
          }, [
            (item.pathName)
              ? (_openBlock(), _createElementBlock("svg", _hoisted_4, [
                  _createElementVNode("use", {
                    href: `#side-icon${
                _ctx.hasActiveRoute(item).value ? '-active' : ''
              }-${i + 1}--sprite`
                  }, null, 8, _hoisted_5)
                ]))
              : _createCommentVNode("", true),
            (item.pathName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_router_link, {
                    class: "side__link",
                    to: { name: item.pathName }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        class: _normalizeClass({ side__label_active: _ctx.hasActiveRoute(item).value })
                      }, _toDisplayString(item.label), 3)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]))
              : (_openBlock(), _createBlock(_component_v_expansion_panels, { key: 2 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_expansion_panel, { class: "side__expansionPanel" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, { class: "side__expansionPanelTitle" }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createElementBlock("svg", _hoisted_7, [
                              _createElementVNode("use", {
                                href: `#side-icon${
                      _ctx.hasActiveRoute(item, true).value ? '-active' : ''
                    }-${i + 1}--sprite`
                              }, null, 8, _hoisted_8)
                            ])),
                            _createElementVNode("span", {
                              class: _normalizeClass({
                    side__label_active: _ctx.hasActiveRoute(item, true).value,
                  })
                            }, _toDisplayString(item.label), 3)
                          ]),
                          _: 2
                        }, 1024),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.subNav, (subnav) => {
                          return (_openBlock(), _createBlock(_component_v_expansion_panel_text, {
                            key: subnav.id,
                            class: "side__expansionPanelText"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_router_link, {
                                class: "side__link",
                                to: { name: subnav.pathName }
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", {
                                    class: _normalizeClass({
                      side__label_active: _ctx.hasActiveRoute(subnav).value,
                    })
                                  }, _toDisplayString(subnav.label), 3)
                                ]),
                                _: 2
                              }, 1032, ["to"])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
          ], 2))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actionsList, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "side__item",
            key: item.id
          }, [
            (_openBlock(), _createElementBlock("svg", _hoisted_10, [
              _createElementVNode("use", {
                href: `#actions-icon-${i + 1}--sprite`
              }, null, 8, _hoisted_11)
            ])),
            _createElementVNode("div", _hoisted_12, _toDisplayString(item.label), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}