<template>
  <div class="commonEditor">
    <span class="commonEditor__label">
      <slot>{{ label }} </slot>
    </span>
    <div class="commonEditor__input">
      <div class="commonEditor__input__toolbar">
        <button @click="toggleBold" :class="{ active: isActive('bold') }">
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${BoldIcon})` }"
          ></div>
        </button>
        <button @click="toggleItalic" :class="{ active: isActive('italic') }">
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${ItalicIcon})` }"
          ></div>
        </button>
        <button
          @click="toggleUnderline"
          :class="{ active: isActive('underline') }"
        >
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${UnderlineIcon})` }"
          ></div>
        </button>

        <button @click="toggleStrike" :class="{ active: isActive('strike') }">
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${StrikeIcon})` }"
          ></div>
        </button>

        <button
          @click="setHighlight('yellow')"
          :class="{ active: isActive({ highlight: 'yellow' }) }"
        >
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${PencilIcon})` }"
          ></div>
        </button>

        <button @click="toggleCode" :class="{ active: isActive('code') }">
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${CodeIcon})` }"
          ></div>
        </button>

        <button @click="setLink" :class="{ active: isActive('link') }">
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${LinkIcon})` }"
          ></div>
        </button>

        <div class="commonEditor__vertical-hr"></div>

        <button
          @click="setTextAlign('left')"
          :class="{ active: isActive({ textAlign: 'left' }) }"
        >
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${LeftIcon})` }"
          ></div>
        </button>

        <button
          @click="setTextAlign('center')"
          :class="{ active: isActive({ textAlign: 'center' }) }"
        >
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${CenterIcon})` }"
          ></div>
        </button>

        <button
          @click="setTextAlign('right')"
          :class="{ active: isActive({ textAlign: 'right' }) }"
        >
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${RightIcon})` }"
          ></div>
        </button>

        <div class="commonEditor__vertical-hr"></div>

        <button @click="addImage">
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${GalleryIcon})` }"
          ></div>
        </button>

        <button
          @click="toggleBulletList"
          :class="{ active: isActive('bulletList') }"
        >
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${DotListIcon})` }"
          ></div>
        </button>
        <button
          @click="toggleOrderedList"
          :class="{ active: isActive('orderedList') }"
        >
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${NumberListIcon})` }"
          ></div>
        </button>

        <button
          @click="toggleBlockquote"
          :class="{ active: isActive('blockquote') }"
        >
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${QuoteIcon})` }"
          ></div>
        </button>

        <button
          @click="setTextAlign('justify')"
          :class="{ active: isActive({ textAlign: 'justify' }) }"
        >
          <div
            class="commonEditor__input__toolbar__icon"
            :style="{ maskImage: `url(${JustifyIcon})` }"
          ></div>
        </button>
      </div>
      <editor-content :editor="editor" class="commonEditor__editor" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount, ref, watch } from "vue";
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Image from "@tiptap/extension-image";
import Code from "@tiptap/extension-code";
import Strike from "@tiptap/extension-strike";
import Blockquote from "@tiptap/extension-blockquote";
import Link from "@tiptap/extension-link";
import Highlight from "@tiptap/extension-highlight";

import BoldIcon from "./icons/text-bold.svg";
import ItalicIcon from "./icons/text-italic.svg";
import UnderlineIcon from "./icons/text-underline.svg";
import PencilIcon from "./icons/pencil.svg";
import NumberListIcon from "./icons/number-list.svg";
import DotListIcon from "./icons/noun-number-list.svg";
import LeftIcon from "./icons/textalign-left.svg";
import CenterIcon from "./icons/textalign-center.svg";
import RightIcon from "./icons/textalign-right.svg";
import GalleryIcon from "./icons/gallery.svg";
import StrikeIcon from "./icons/text.svg";
import CodeIcon from "./icons/scroll.svg";
import QuoteIcon from "./icons/quote-down.svg";
import LinkIcon from "./icons/link-add.svg";
import JustifyIcon from "./icons/textalign-justifycenter.svg";

export default defineComponent({
  name: "EnhancedTextEditor",
  components: {
    EditorContent,
  },

  emits: ["update:modelValue"],

  props: {
    label: String,
    modelValue: String,
  },

  setup(props, { emit }) {
    const editor = ref<Editor | null>(null);

    onMounted(() => {
      editor.value = new Editor({
        content: props.modelValue,
        extensions: [
          StarterKit,
          Underline,
          Image,
          Code,
          Strike,
          Blockquote,
          Link,
          TextAlign.configure({
            types: ["heading", "paragraph"],
            alignments: ["left", "center", "right", "justify"],
          }),
          Highlight.configure({
            multicolor: true,
          }),
        ],
      });

      if (editor.value) {
        editor.value.on("update", ({ editor }) => {
          emit("update:modelValue", editor.getHTML());
        });
      }
    });

    onBeforeUnmount(() => {
      if (editor.value) {
        editor.value.destroy();
      }
    });

    const toggleBold = () => editor.value?.chain().focus().toggleBold().run();
    const toggleItalic = () =>
      editor.value?.chain().focus().toggleItalic().run();
    const toggleUnderline = () =>
      editor.value?.chain().focus().toggleUnderline().run();
    const setTextAlign = (align: string) =>
      editor.value?.chain().focus().setTextAlign(align).run();
    const toggleBulletList = () =>
      editor.value?.chain().focus().toggleBulletList().run();
    const toggleOrderedList = () =>
      editor.value?.chain().focus().toggleOrderedList().run();
    const addImage = () => {
      const url = prompt("Enter image URL:");
      if (url) {
        editor.value?.chain().focus().setImage({ src: url }).run();
      }
    };
    const toggleStrike = () =>
      editor.value?.chain().focus().toggleStrike().run();
    const toggleBlockquote = () =>
      editor.value?.chain().focus().toggleBlockquote().run();
    const toggleCode = () => editor.value?.chain().focus().toggleCode().run();
    const setLink = () => {
      const url = prompt("Enter URL:");
      if (url) {
        editor.value
          ?.chain()
          .focus()
          .toggleLink({ href: url, target: "_blank" })
          .run();
      }
    };
    const setHighlight = (color: string) => {
      editor.value?.chain().focus().toggleHighlight({ color }).run();
    };

    const isActive = (type: string | Record<string, any>) =>
      editor.value?.isActive(type);

    watch(
      () => props.modelValue,
      () => {
        if (editor.value && editor.value.getHTML() !== props.modelValue) {
          editor.value.commands.setContent(props.modelValue as any);
        }
      }
    );

    return {
      editor,
      toggleBold,
      toggleItalic,
      toggleUnderline,
      setTextAlign,
      toggleBulletList,
      toggleOrderedList,
      addImage,
      isActive,
      toggleStrike,
      toggleBlockquote,
      toggleCode,
      setLink,
      setHighlight,

      BoldIcon,
      ItalicIcon,
      UnderlineIcon,
      LeftIcon,
      CenterIcon,
      RightIcon,
      NumberListIcon,
      DotListIcon,
      GalleryIcon,
      StrikeIcon,
      CodeIcon,
      QuoteIcon,
      LinkIcon,
      JustifyIcon,
      PencilIcon,
    };
  },
});
</script>

<style scoped lang="stylus">
.commonEditor {
  &__label {
    color: $as-dark-grey;
    font-size: 14px;
    margin-bottom: 4px;
    display: block;
    padding: 0;
    line-height: 16px;
  }

  &__input {
    border: 1px solid #ccc;
    border-radius: 6px;

    &:focus-within {
      border-color: $as-active-green;
    }

    &__toolbar {
      display: flex;
      gap: 14px;
      padding: 8px 16px;
      background-color: #fff;
      border-radius: 6px 6px 0 0;

    > button {
      cursor: pointer;

      &.active {
        color: $as-active-green;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

      &__icon {
        width: 18px;
        height: 18px;
        mask-size: cover;
        background-color: currentColor;

        +mediaGiant() {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &__vertical-hr {
    width: 1px;
    height: 20px;
    background-color: $magistracy-medium-grey;
  }

  &__editor {
    width: 100%;
    padding: 12px 16px;
    border-radius: 0 0 6px 6px;
    background-color: $as-extra-light-bg;
    min-height: 100px;
  }
}

::v-deep {
  .ProseMirror {
    outline: none;
    min-height: 100px;
    getFontSmall();
    color: $as-black;
  }

  .tiptap {
      blockquote {
      border-left: 3px solid grey;
      margin: 8px 0;
      padding-left: 8px;
    }
  }
}
</style>
