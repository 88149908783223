<template>
  <CommonFormContainer title="Вы научитесь">
    <div class="programs-input-box">
      <CommonInput
        v-for="(item, i) in formModel.skills"
        :key="`goal-text-${i}`"
        v-model="formModel.skills[i]"
        placeholder="Введите текст"
        @update:model-value="$emit('updateValues', formModel)"
      >
        <template #suffix>
          <svg
            class="programs-close"
            @click="
              removeItem(i);
              $emit('updateValues', formModel);
            "
          >
            <use xlink:href="#close-icon--sprite" />
          </svg>
        </template>
      </CommonInput>

      <svg
        class="programs-plus"
        @click="
          pushNewItem();
          $emit('updateValues', formModel);
        "
      >
        <use xlink:href="#plus-curator--sprite" />
      </svg>
    </div>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";

export default defineComponent({
  name: "FormSkills",

  components: {
    CommonFormContainer,
    CommonInput,
  },

  props: ["form"],

  setup(props) {
    const formModel = reactive({
      skills: [""],
    });

    const pushNewItem = () => {
      formModel.skills.push("");
    };

    const removeItem = (index: number) => {
      formModel.skills.splice(index, 1);
    };

    watch(
      () => props.form,
      (newValue: any) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
      pushNewItem,
      removeItem,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
