import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from './down.svg'


const _withScopeId = (n: any) => (_pushScopeId("data-v-662ef97a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  src: _imports_0,
  class: "custom-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", _hoisted_1))
}