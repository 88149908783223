<template>
  <button
    class="commonButton"
    :class="[colorType, { outline, disabled, text }]"
    :disabled="disabled"
    @click="click"
  >
    <span v-if="$slots.icon" class="commonButton__icon">
      <slot name="icon"></slot>
    </span>
    <span>
      <slot>{{ label }}</slot>
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommonButton",

  props: {
    label: {
      type: String,
    },

    colorType: {
      type: String,
      required: true,
      default: "red",
      validator: (value: string) =>
        ["gold", "blue", "green-light", "green-dark", "red", "grey"].includes(
          value
        ),
    },

    outline: {
      type: Boolean,
      default: false,
    },

    text: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(_, { emit }) {
    const click = () => {
      emit("click");
    };

    return {
      click,
    };
  },
});
</script>

<style lang="stylus" scoped>

common-styles(background) {
  background-color: background
  color: #fff
  border-color: background
}

outline-styles(background, color) {
  background-color: background
  color: color
  border-color: color
}

.commonButton {
  padding: 12px 24px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  border: 1px solid transparent;
  getFontSmall();
  font-weight: 600;

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.green-light {
    common-styles($as-active-green)

    &.text {
      color: $as-active-green
    }

    &.outline {
      outline-styles(#fff, $as-active-green)
    }

    &:hover:not(.disabled) {
      outline-styles($active-green-hover, #fff)

      &.outline {
        common-styles($as-active-green)
      }
    }
  }

  &.blue {
    common-styles($as-blue)

    &.text {
      color: $as-blue
    }

    &.outline {
      outline-styles(#fff, $as-blue)
    }

    &:hover:not(.disabled) {
      outline-styles($as-light-blue, $as-blue)

      &.outline {
        common-styles($as-blue)
      }
    }
  }

  &.gold {
    common-styles($as-gold)

    &.text {
      color: $as-gold
    }

    &.outline {
      outline-styles(#fff, $as-gold)
    }

    &:hover:not(.disabled) {
      outline-styles($as-light-gold, $as-gold)

      &.outline {
        common-styles($as-gold)
      }
    }
  }

  &.red {
    common-styles($as-red-aspirant)

    &.text {
      color: $as-red-aspirant
    }

    &.outline {
      outline-styles(#fff, $as-red-aspirant)
    }

    &:hover:not(.disabled) {
      outline-styles($as-red-bg, $as-red-aspirant)

      &.outline {
        common-styles($as-red-aspirant)
      }
    }
  }

  &.green-dark {
    common-styles($as-green-magistr)

    &.text {
      color: $as-green-magistr
    }

    &.outline {
      outline-styles(#fff, $as-green-magistr)
    }

    &:hover:not(.disabled) {
      outline-styles($as-green-dark-bg, $as-green-magistr)

      &.outline {
        common-styles($as-green-magistr)
      }
    }
  }

  &.grey {
    common-styles($as-grey-system)

    &.text {
      color: $as-grey-system
    }

    &.outline {
      outline-styles(#fff, $as-grey-system)
    }

    &:hover:not(.disabled) {
      outline-styles($as-grey-system-light, $as-grey-system)

      &.outline {
        common-styles($as-grey-system)
      }
    }
  }

  &.text {
    background: none;
    border: none;
    padding: 0;

    &:hover:not(.disabled) {
      color: $hover-color;
      background: none;
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-right: 6px;
  }
}
</style>
