export const notificationsList = [
  "Программа “Образовательная программа 1” требует модерации",
  "Добавлены новые темы для программы “Образовательная программа 2”",
  "Преподаватель Иванов В.И. завершил создание лекций для курса “Образовательная программа 3”",
  "Программа “Образовательная программа 1” требует модерации",
  "Добавлены новые темы для программы “Образовательная программа 2”",
  "Преподаватель Иванов В.И. завершил создание лекций для курса “Образовательная программа 3”",
  "Программа “Образовательная программа 1” требует модерации",
  "Добавлены новые темы для программы “Образовательная программа 2”",
  "Преподаватель Иванов В.И. завершил создание лекций для курса “Образовательная программа 3”",
];
