<template>
  <CommonFormContainer title="Преимущества">
    <div>
      <span class="programs-label"> Главные </span>

      <div class="programs-input-box">
        <CommonInput
          v-for="(item, i) in formModel.main"
          :key="`goal-text-${i}`"
          v-model="formModel.main[i]"
          placeholder="Введите текст"
          @update:model-value="$emit('updateValues', formModel)"
        >
          <template #suffix>
            <svg
              class="programs-close"
              @click="
                removeItem(i, formModel.main);
                $emit('updateValues', formModel);
              "
            >
              <use xlink:href="#close-icon--sprite" />
            </svg>
          </template>
        </CommonInput>

        <svg
          class="programs-plus"
          @click="
            pushNewItem(formModel.main);
            $emit('updateValues', formModel);
          "
        >
          <use xlink:href="#plus-curator--sprite" />
        </svg>
      </div>
    </div>

    <div>
      <span class="programs-label"> Дополнительные </span>
      <div class="programs-input-box">
        <CommonInput
          v-for="(item, i) in formModel.about"
          :key="`goal-text-${i}`"
          v-model="formModel.about[i]"
          placeholder="Введите текст"
          @update:model-value="$emit('updateValues', formModel)"
        >
          <template #suffix>
            <svg
              class="programs-close"
              @click="
                removeItem(i, formModel.about);
                $emit('updateValues', formModel);
              "
            >
              <use xlink:href="#close-icon--sprite" />
            </svg>
          </template>
        </CommonInput>

        <svg
          class="programs-plus"
          @click="
            pushNewItem(formModel.about);
            $emit('updateValues', formModel);
          "
        >
          <use xlink:href="#plus-curator--sprite" />
        </svg>
      </div>
    </div>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";

export default defineComponent({
  name: "FormAdvantages",

  components: {
    CommonFormContainer,
    CommonInput,
  },

  props: ["form"],

  setup(props) {
    const formModel = reactive({
      main: [""],
      about: [""],
    });

    const pushNewItem = (item: any) => {
      item.push("");
    };

    const removeItem = (index: number, arr: any) => {
      arr.splice(index, 1);
    };

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
      pushNewItem,
      removeItem,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
