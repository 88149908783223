<template>
  <CommonFormContainer title="Дата и статус">
    <div class="programs-date-list">
      <CommonDatePicker
        v-model="formModel.start_at"
        class="programs-date"
        label="Дата старта"
        placeholder="дд/мм/гггг"
        @update:model-value="$emit('updateValues', formModel)"
      />

      <CommonDatePicker
        v-model="formModel.end_at"
        class="programs-date"
        label="Дата окончания"
        placeholder="дд/мм/гггг"
        @update:model-value="$emit('updateValues', formModel)"
      />
    </div>

    <CommonSelect
      v-model="formModel.status"
      :options="['Активная', 'В архиве', 'Отложена']"
      placeholder="Выберите из списка"
      :multiple="false"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Тип
    </CommonSelect>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonDatePicker } from "@/components/NewComponents/CommonDatePicker";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";

export default defineComponent({
  name: "FormDate",

  components: {
    CommonFormContainer,
    CommonDatePicker,
    CommonSelect,
  },

  props: ["form"],

  setup(props) {
    const formModel = reactive({
      start_at: "",
      end_at: "",
      status: "",
    });

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
    };
  },
});
</script>

<style lang="stylus" scoped>
.programs-date-list {
  display: flex;
  align-items: center;
  gap: 16px;
}
</style>
