<template>
  <CommonFormContainer title="Цели">
    <CommonTextEditor
      v-model="formModel.goal_text"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Описание
    </CommonTextEditor>

    <div class="programs-input-box">
      <CommonInput
        v-for="(item, i) in formModel.list1"
        :key="`goal-text-${i}`"
        v-model="formModel.list1[i]"
        placeholder="Введите текст"
        @update:model-value="$emit('updateValues', formModel)"
      />

      <svg
        class="programs-plus"
        @click="
          pushNewItem();
          $emit('updateValues', formModel);
        "
      >
        <use xlink:href="#plus-curator--sprite" />
      </svg>
    </div>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonTextEditor } from "@/components/NewComponents/CommonTextEditor";

export default defineComponent({
  name: "FormGoals",

  components: {
    CommonFormContainer,
    CommonInput,
    CommonTextEditor,
  },

  props: ["form"],

  setup(props) {
    const formModel = reactive({
      goal_text: "",
      list1: [""],
    });

    const pushNewItem = () => {
      formModel.list1.push("");
    };

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
      pushNewItem,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
