import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-60ca8ee5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "generalPage__wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainContainer = _resolveComponent("MainContainer")!
  const _component_NotificationsContainer = _resolveComponent("NotificationsContainer")!
  const _component_LayoutBase = _resolveComponent("LayoutBase")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.hasGeneralPage)
    ? (_openBlock(), _createBlock(_component_LayoutBase, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_MainContainer, { class: "generalPage__main" }),
            _createVNode(_component_NotificationsContainer, { class: "generalPage__notifications" })
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}