import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4cfef3e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "commonComponentHeader" }
const _hoisted_2 = { class: "commonComponentHeader__titleBox" }
const _hoisted_3 = { class: "commonComponentHeader__title" }
const _hoisted_4 = { class: "commonComponentHeader__filtersBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonBreadcrumbs = _resolveComponent("CommonBreadcrumbs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.items)
        ? (_openBlock(), _createBlock(_component_CommonBreadcrumbs, {
            key: 0,
            items: _ctx.items
          }, null, 8, ["items"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "filter-box", {}, undefined, true)
    ])
  ]))
}