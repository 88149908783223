<template>
  <LayoutHomepage :isHigher="false">
    <div>
      <section class="intro">
        <div class="intro-layout">
          <div class="container">
            <div class="intro__header">
              <div class="intro-picture">
                <svg class="education-icon">
                  <use xlink:href="#education--sprite" />
                </svg>
              </div>
              <div class="intro-title">
                <h1>Московская Медицина. Образование</h1>
                <div class="intro-text">
                  Центр образования в здравоохранении
                </div>
              </div>
            </div>

            <!-- <div class="intro__body">
              <div class="intro-counter">
                <div class="counter-text">
                  До конца набора в&nbsp;магистратуру осталось
                </div>
                <div class="counter-body">
                  <div class="counter-result">18 дней</div>
                  <a href="" class="btn">Подать заявку</a>
                </div>
              </div>
            </div> -->
          </div>
          <div class="intro__slogan">Развивайте свое будущее вместе с нами</div>
        </div>
      </section>

      <div class="container">
        <div class="education">
          <div class="education__item">
            <router-link
              :to="{ name: RouteName.HOME_MAGISTRACY }"
              class="btn btn-primary"
            >
              Магистратура
            </router-link>
          </div>
          <div class="education__item">
            <router-link
              :to="{ name: RouteName.HOME_ORDINAT }"
              class="btn btn-primary"
            >
              Ординатура
            </router-link>
          </div>
          <div class="education__item">
            <router-link
              :to="{ name: RouteName.COURSES_ASPIRANT }"
              class="btn btn-red"
            >
              Аспирантура
            </router-link>
          </div>
          <div class="education__item">
            <router-link
              :to="{ name: RouteName.COURSES_DPO }"
              class="btn btn-secondary"
            >
              Дополнительное образование
            </router-link>
          </div>
        </div>
      </div>

      <section class="description">
        <div class="description__row">
          <div class="description__col">
            <div class="row">
              <div class="description__item">
                <div class="digit">8000+</div>
                <p>слушателей с&nbsp;2016&nbsp;года</p>
              </div>
              <div class="description__item">
                <div class="digit">60+</div>
                <p>образовательных программ</p>
              </div>
              <div class="description__item">
                <div class="digit">100 000+</div>
                <p>учебных материалов в&nbsp;библиотеке</p>
              </div>
              <div class="description__item">
                <div class="digit">&infin;</div>
                <p>непрерывное образование</p>
              </div>
            </div>
          </div>

          <div class="description__col pl-xl-5">
            <h2>Московская медицина. Образование —</h2>
            <p>
              уникальный образовательный центр очного, дистанционного
              и&nbsp;онлайн-образования.
            </p>
            <p>
              Развивайте компетенции и&nbsp;формируйте успешную карьеру
              с&nbsp;нами!
            </p>
          </div>
        </div>
      </section>
      <section class="subscription">
        <div class="container">
          <div class="row">
            <div class="subscription__col">
              <h2>Подберем идеальную программу для вас</h2>
              <div class="subscription-title">Оставить заявку</div>
            </div>

            <div class="subscription__col pl-xl-5">
              <form @submit.prevent="sendForm(true)">
                <div class="form-input">
                  <input
                    v-model="formModel1.name"
                    type="text"
                    class="form-control"
                    placeholder="Имя *"
                  />
                </div>
                <div class="form-input">
                  <input
                    v-model="formModel1.phone"
                    type="text"
                    class="form-control"
                    placeholder="Телефон *"
                  />
                </div>
                <div class="form-input">
                  <input
                    v-model="formModel1.email"
                    type="email"
                    class="form-control"
                    placeholder="E-mail *"
                  />
                </div>
                <div class="form-input">
                  <select
                    class="selectpicker"
                    title="Выберите направление обучения"
                  >
                    <option>Магистратура</option>
                    <option>Ординатура</option>
                    <option>Аспирантура</option>
                    <option>ДПО</option>
                  </select>
                </div>
                <div class="custom-control custom-checkbox">
                  <input
                    v-model="isConfidencial1"
                    type="checkbox"
                    class="custom-control-input"
                    id="privacy"
                  />
                  <label class="custom-control-label form__agree" for="privacy">
                    Я согласен с условиями
                    <a
                      href="https://ol-test.api.niioz.ru/storage/264/politics_pd.pdf"
                      style="color: #fff"
                      target="_blank"
                      class="form__link"
                    >
                      политики обработки персональных данных
                    </a>
                    и даю согласие на обработку персональных данных
                  </label>
                </div>
                <div class="form-submit">
                  <button type="submit" class="btn">Отправить</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section class="comments">
        <div class="row">
          <div class="section-title">
            <h2>Отзывы наших студентов</h2>
          </div>
          <carousel class="carousel-reviews" :items-to-show="reviewSlidesCount">
            <slide class="carousel-cell">
              <div class="reviews-item">
                <div class="reviews-card">
                  <div class="reviewsList__itemHeader">
                    <img
                      class="reviewsList__img"
                      src="@/assets/img/person-img.jpg"
                    />

                    <div class="reviewsList__titleBox">
                      <b style="font-weight: bold">Елена Яворская</b>
                      <p>врач-невролог</p>
                    </div>
                  </div>

                  <blockquote
                    class="reviewsList__blockquote"
                    style="text-align: left"
                  >
                    Понравилось, что в курсе было уделено внимание действующему
                    законодательству и механизмам страхования. Мне это помогли
                    укрепить некоторые свои теоретические знания. В программе
                    были охвачены важные темы, такие как анализ страховых
                    случаев, оценка качества предоставляемых медицинских услуг и
                    работа с данными, что дает возможность более эффективно
                    решать задачи в своей деятельности.
                  </blockquote>
                </div>
              </div>
            </slide>
            <slide class="carousel-cell">
              <div class="reviews-item">
                <div class="reviews-card">
                  <div class="reviewsList__itemHeader">
                    <img
                      class="reviewsList__img"
                      src="@/assets/img/person-img.jpg"
                    />

                    <div class="reviewsList__titleBox">
                      <b style="font-weight: bold">Дмитрий Соколов</b>
                      <p>главный врач</p>
                    </div>
                  </div>

                  <blockquote
                    class="reviewsList__blockquote"
                    style="text-align: left"
                  >
                    Сам для себя неожиданно осознал, что все это время очень
                    неэффективно выстраивал отношения с некоторыми коллегами, от
                    чего периодически узнавал о критичных вещах в последний
                    момент – замалчивали, боялись подойти. Будем работать, что
                    поделать.
                  </blockquote>
                </div>
              </div>
            </slide>
            <slide class="carousel-cell">
              <div class="reviews-item">
                <div class="reviews-card">
                  <div class="reviewsList__itemHeader">
                    <img
                      class="reviewsList__img"
                      src="@/assets/img/person-img.jpg"
                    />

                    <div class="reviewsList__titleBox">
                      <b style="font-weight: bold">Екатерина Пелевина</b>
                      <p>заведующий отделением – врач-терапевт</p>
                    </div>
                  </div>

                  <blockquote
                    class="reviewsList__blockquote"
                    style="text-align: left"
                  >
                    Программа формирует навыки критического анализа и оценки
                    правомерности решений в системе обязательного медицинского
                    страхования. Теперь я лучше разбираюсь в тонкостях
                    законодательства и могу уверенно применять полученные знания
                    в своей работе.
                  </blockquote>
                </div>
              </div>
            </slide>

            <slide class="carousel-cell">
              <div class="reviews-item">
                <div class="reviews-card">
                  <div class="reviewsList__itemHeader">
                    <img
                      class="reviewsList__img"
                      src="@/assets/img/person-img.jpg"
                    />

                    <div class="reviewsList__titleBox">
                      <b style="font-weight: bold">Екатерина Лебедева</b>
                      <p>врач-терапевт</p>
                    </div>
                  </div>

                  <blockquote
                    class="reviewsList__blockquote"
                    style="text-align: left"
                  >
                    Я научилась не только проводить экспертизу, но и управлять
                    качеством медицинских услуг, что крайне важно для повышения
                    уровня обслуживания пациентов. Теперь я уверенно справляюсь
                    с документальным сопровождением. Рекомендую всем медицинским
                    специалистам!
                  </blockquote>
                </div>
              </div>
            </slide>

            <slide class="carousel-cell">
              <div class="reviews-item">
                <div class="reviews-card">
                  <div class="reviewsList__itemHeader">
                    <img
                      class="reviewsList__img"
                      src="@/assets/img/person-img.jpg"
                    />

                    <div class="reviewsList__titleBox">
                      <b style="font-weight: bold">Мария Сидорова</b>
                      <p>медицинская сестра</p>
                    </div>
                  </div>

                  <blockquote
                    class="reviewsList__blockquote"
                    style="text-align: left"
                  >
                    Обучение помогло мне глубже понять механизмы передачи
                    инфекций и важность ранней диагностики. Практические занятия
                    позволили нам отработать навыки, необходимые для быстрой и
                    эффективной реакции в экстренных ситуациях. Эта программа
                    повысила уверенность в своих силах и готовность действовать
                    в условиях неопределенности
                  </blockquote>
                </div>
              </div>
            </slide>

            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </div>
      </section>
      <section class="advantages">
        <h2 class="title-section">Наши преимущества</h2>
        <div class="container">
          <div class="advantages__row">
            <div class="advantages__item">
              <div class="advantage-card">
                <span>
                  Современные направления и актуальные программы обучения
                </span>
              </div>
            </div>
            <div class="advantages__item">
              <div class="advantage-card">
                <span>Высококвалифицированный преподавательский состав</span>
              </div>
            </div>
            <div class="advantages__item">
              <div class="advantage-card">
                <span>
                  Живое общение с лучшими экспертами и практикующими
                  специалистами
                </span>
              </div>
            </div>
            <div class="advantages__item">
              <div class="advantage-card">
                <span>Помогаем уверенно и эффективно добиваться успеха</span>
              </div>
            </div>
            <div class="advantages__item">
              <div class="advantage-card">
                <span>Поддержка куратора на всем протяжении обучения</span>
              </div>
            </div>
            <div class="advantages__item">
              <div class="advantage-card">
                <span>Погружение в профессиональное сообщество</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="subscription">
        <div class="container">
          <div class="row">
            <div class="subscription__col">
              <img
                src="img/icons/subscription.svg"
                width="70"
                class="mb-3"
                alt=""
              />
              <h2>
                Рассказываем о самых интересных и важных событиях в наших
                рассылках
              </h2>
            </div>

            <div class="subscription__col pl-xl-5">
              <form @submit.prevent="sendForm(false)">
                <div class="form-input">
                  <input
                    v-model="formModel2.name"
                    type="text"
                    class="form-control"
                    placeholder="Имя *"
                  />
                </div>
                <div class="form-input">
                  <input
                    v-model="formModel2.phone"
                    type="text"
                    class="form-control"
                    placeholder="Телефон *"
                  />
                </div>
                <div class="form-input">
                  <input
                    v-model="formModel2.email"
                    type="email"
                    class="form-control"
                    placeholder="E-mail *"
                  />
                </div>
                <div class="custom-control custom-checkbox">
                  <input
                    v-model="isConfidencial2"
                    type="checkbox"
                    class="custom-control-input"
                    id="privacy2"
                  />
                  <label
                    class="custom-control-label form__agree"
                    for="privacy2"
                  >
                    Я согласен с условиями
                    <a
                      href="https://ol-test.api.niioz.ru/storage/264/politics_pd.pdf"
                      style="color: white"
                      target="_blank"
                      class="form__link"
                    >
                      политики обработки персональных данных
                    </a>
                    и даю согласие на обработку персональных данных
                  </label>
                </div>
                <div class="form-submit">
                  <button type="submit" class="btn">Подписаться</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section class="documents">
        <h2 class="title-section">Наши лицензии</h2>
        <carousel
          :items-to-show="documentsSlidesCount"
          class="carousel-documents"
        >
          <slide class="carousel-cell">
            <a href="" class="documents-item" target="_blank">
              <img
                src="@/assets/img/certificate.jpg"
                alt="Свидетельстро о государственной аккредитации"
              />
            </a>
          </slide>
          <slide class="carousel-cell">
            <a href="" class="documents-item" target="_blank">
              <img src="@/assets/img/document1.jpg" alt="Выписка" />
            </a>
          </slide>
          <slide class="carousel-cell">
            <a href="" class="documents-item" target="_blank">
              <img
                src="@/assets/img/license.jpg"
                alt="Лицензия на образовательную деятельность"
              />
            </a>
          </slide>
          <slide class="carousel-cell">
            <a href="" class="documents-item" target="_blank">
              <img
                src="@/assets/img/document2.jpg"
                alt="Выписка из реестра лицензий"
              />
            </a>
          </slide>

          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
      </section>
    </div>
  </LayoutHomepage>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeUnmount,
  onBeforeMount,
  ref,
  computed,
  reactive,
} from "vue";
import { LayoutHomepage } from "@/layouts/LayoutHomepage";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { RouteName } from "@/constants";
import { basicFetch } from "@/store/storeUtils";
import { api } from "@/utils/apiInstance";
import { Statuses } from "@/constants/statuses";
import { useToast } from "vue-toastification";

import "@/assets/img/icons/education.svg?sprite";

export default defineComponent({
  name: "HomePageMain",

  components: {
    LayoutHomepage,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  setup() {
    const toast = useToast();

    const formModel1 = reactive({
      name: "",
      phone: "",
      email: "",
    });

    const formModel2 = reactive({
      name: "",
      phone: "",
      email: "",
    });

    const isConfidencial1 = ref(false);
    const isConfidencial2 = ref(false);

    const reviewSlidesCount = computed(() => {
      if (innerWidth.value < 600) {
        return 1;
      } else if (innerWidth.value < 1024) {
        return 2;
      }
      return 3;
    });

    const documentsSlidesCount = computed(() => {
      if (innerWidth.value < 600) {
        return 1;
      } else if (innerWidth.value < 1024) {
        return 2;
      } else if (innerWidth.value < 1200) {
        return 3;
      }
      return 4;
    });

    const innerWidth = ref(window.innerWidth);

    const setMainCss = () => {
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.id = "main-css";

      document.head.appendChild(link);

      link.href = "/assets/css/main.css";
    };

    const removeMainCss = () => {
      document.getElementById("main-css")?.remove();
    };

    addEventListener("resize", (event: any) => {
      innerWidth.value = event?.target?.innerWidth;
    });

    document.body.classList.remove("courses");

    const toggleEnrollments = async (isFirstForm: boolean) => {
      await basicFetch({
        method: api.sendPost,
        props: {
          url:
            window.location.hostname === "localhost"
              ? `/api/enrollments`
              : `/backend/api/enrollments`,
          data: {
            ...(isFirstForm ? formModel1 : formModel2),
            ...(isFirstForm
              ? {
                  degree_id: 1,
                }
              : {
                  target: "main_subscription",
                }),
          },
        },
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
        callback: async (fetchState) => {
          if (fetchState === Statuses.FULFILLED) {
            formModel1.name = "";
            formModel1.phone = "";
            formModel1.email = "";
            isConfidencial1.value = false;
            formModel2.name = "";
            formModel2.phone = "";
            formModel2.email = "";
            isConfidencial2.value = false;
            toast.success("Заявка принята");
          }
        },
      });
    };

    const sendForm = (isFirstForm: boolean) => {
      if (
        (isFirstForm && !isConfidencial1.value) ||
        (!isFirstForm && !isConfidencial2.value)
      ) {
        toast.error(
          "Необходимо принять согласие с политикой конфиденциальности"
        );
      } else if (
        (isFirstForm &&
          isConfidencial1.value &&
          formModel1.name &&
          formModel1.phone &&
          formModel1.email) ||
        (!isFirstForm &&
          isConfidencial2.value &&
          formModel2.name &&
          formModel2.phone &&
          formModel2.email)
      ) {
        toggleEnrollments(isFirstForm);
      }
    };

    onBeforeMount(() => setMainCss());

    onBeforeUnmount(() => removeMainCss());

    return {
      innerWidth,
      RouteName,
      reviewSlidesCount,
      documentsSlidesCount,
      formModel1,
      formModel2,
      isConfidencial1,
      isConfidencial2,
      sendForm,
    };
  },
});
</script>

<style lang="stylus" scoped>
.intro {
  background-image: url("@/assets/img/intro-bg.jpg");
}
.education-icon {
  max-width: 10vw;
  +mediaExtraLarge() {
    max-width: 190px;
  }
}
.intro-title {
  font-size: 1rem;

  +mediaDesktop() {
    font-size: 3.5rem;
  }
}
.comment-body,
.comment-head {
  text-align: left;
}
.reviews-item,
.reviews-card {
  height: 100%;
  text-align: left;
}
.form {
  &__agree {
    max-width: 350px;
    color: $color-white;
  }
  &__link {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.reviewsList {
  display: grid;
  grid-template-columns: repeat(1, 80%);
  gap: 40px;
  text-align: center;

  &__itemHeader {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px;
  }

  &__img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    opacity: .7;
  }

  &__video {
    border-radius: 20px;
  }

  &__blockquote {
    font-style: italic;
    padding: 15px;
    background-color: $color-bg-grey;
    border-radius: 20px;
  }

  +mediaTablet() {
    grid-template-columns: repeat(3, 32%);
  }
}
</style>
