import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonComponentHeader = _resolveComponent("CommonComponentHeader")!
  const _component_CommonTable = _resolveComponent("CommonTable")!
  const _component_CommonPagination = _resolveComponent("CommonPagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CommonComponentHeader, {
      title: "Архив программ",
      items: _ctx.breadcrumbsItems
    }, null, 8, ["items"]),
    _createVNode(_component_CommonTable, {
      "head-list": _ctx.listHeader,
      "body-list": _ctx.filteredBodyList
    }, null, 8, ["head-list", "body-list"]),
    (_ctx.hasShowPagination)
      ? (_openBlock(), _createBlock(_component_CommonPagination, {
          key: 0,
          length: _ctx.pagesCount,
          "current-page": _ctx.page,
          onChangePage: _ctx.changePage,
          class: "programsPage__pagination"
        }, null, 8, ["length", "current-page", "onChangePage"]))
      : _createCommentVNode("", true)
  ], 64))
}