<template>
  <LayoutHomepage
    isHigher
    :emailsList="hasCourses ? emailsDpoList : emailsList"
    :phonesList="hasCourses ? phonesDpoList : phonesList"
  >
    <CommonIntro />

    <div class="page-section">
      <div class="nav-overflow">
        <nav class="tabs-menu">
          <ul class="nav nav-tabs">
            <li
              class="nav-item intro__nav"
              v-for="item in homeNavList"
              :key="item.label"
              v-show="
                !(
                  (item.label === 'Документы' ||
                    item.label === 'Приемная кампания') &&
                  hasCourses
                )
              "
            >
              <router-link
                :class="[
                  'nav-link',
                  {
                    active:
                      pathName(
                        item.pathOrdinatName,
                        item.pathAspirantName,
                        item.pathCoursesName
                      ).value === route?.name,
                  },
                ]"
                :to="{
                  name: pathName(
                    item.pathOrdinatName,
                    item.pathAspirantName,
                    item.pathCoursesName
                  ).value,
                  query: route.query,
                }"
                @click="toggleBodyStyle"
              >
                {{ item.label }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <h2 class="section-title">Отзывы</h2>

      <div class="reviewsList">
        <div
          class="reviewsList__item"
          v-for="item in programOrd?.reviews"
          :key="item.name"
        >
          <div class="reviewsList__itemHeader">
            <img class="reviewsList__img" src="@/assets/img/person-img.jpg" />

            <div class="reviewsList__titleBox">
              <b>{{ item.name }}</b>
              <p>{{ item.position }}</p>
            </div>
          </div>

          <iframe
            v-if="item.video"
            class="reviewsList__video"
            :src="item.video"
            frameBorder="0"
            allow="clipboard-write; autoplay"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          />
          <blockquote class="reviewsList__blockquote" v-else>
            {{ item.text }}
          </blockquote>
        </div>
      </div>
    </div>

    <CommonApplication :degree_id="degreeId" :program_id="programId" />
  </LayoutHomepage>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from "vue";
import { LayoutHomepage } from "@/layouts/LayoutHomepage";
import { CommonApplication } from "../HomeProgramsListPage/components/CommonApplication";
import { CommonIntro } from "./components/CommonIntro";
import { useRouter, useRoute } from "vue-router";
import {
  homeNavList,
  emailsList,
  emailsDpoList,
  phonesList,
  phonesDpoList,
} from "./constants";
import { RouteName } from "@/constants";
import store from "@/store";

export default defineComponent({
  name: "HomeReviewsPage",

  components: {
    LayoutHomepage,
    CommonApplication,
    CommonIntro,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const programOrd = computed(() => store.getters.programsStore.data);
    const showDiscipline = ref(1);

    const hasOrdinat = computed(() => useRoute()?.path.includes("ordinat"));
    const hasAspirant = computed(() => route?.path.includes("aspirant"));
    const hasCourses = computed(() => route?.path.includes("dpo"));

    const bodyStyleName = computed(() => {
      if (route?.name === RouteName.HOME_ORDINAT_REVIEWS) {
        return "ordinat";
      } else if (route?.name === RouteName.HOME_ASPIRANT_REVIEWS) {
        return "aspirant";
      }
      return "courses";
    });

    const queryId = computed(() => route?.params?.id);

    const programId = computed(() => {
      if (queryId.value) return Number(queryId.value);

      if (route.path.includes("ordinat")) {
        return 1;
      } else if (route.path.includes("aspirant")) {
        return 2;
      }

      return 4;
    });

    const degreeId = computed(() => {
      if (route.path.includes("ordinat")) {
        return 1;
      } else if (route.path.includes("aspirant")) {
        return 2;
      }

      return 4;
    });

    const hasShowDisciplines = (id: number) =>
      computed(() => Number(route.query.id) === id);

    const goToDisciplines = (id: number) => {
      router.push({ query: { id } });
      showDiscipline.value = id;
    };

    const pathName = (ordinatPath: any, aspirantPath: any, coursesPath: any) =>
      computed(() => {
        if (hasOrdinat.value) {
          return ordinatPath;
        } else if (hasAspirant.value) {
          return aspirantPath;
        }
        return coursesPath;
      });

    const toggleBodyStyle = () => {
      document.body.classList.add(bodyStyleName.value);
    };

    onMounted(async () => {
      toggleBodyStyle();

      store.dispatch.programsStore.getPrograms({ program_id: programId.value });
    });

    return {
      programId,
      degreeId,
      hasCourses,
      programOrd,
      showDiscipline,
      homeNavList,
      route,
      emailsList,
      emailsDpoList,
      phonesList,
      phonesDpoList,
      toggleBodyStyle,
      pathName,
      goToDisciplines,
      hasShowDisciplines,
    };
  },
});
</script>

<style lang="stylus" scoped>
.header {
  &__icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
}

.reviewsList {
  display: grid;
  grid-template-columns: repeat(1, 80%);
  gap: 40px;

  &__itemHeader {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px;
  }

  &__img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    opacity: .7;
  }

  &__video {
    border-radius: 20px;
  }

  &__blockquote {
    font-style: italic;
    padding: 15px;
    background-color: $color-bg-grey;
    border-radius: 20px;
  }

  +mediaTablet() {
    grid-template-columns: repeat(3, 32%);
  }
}
</style>
