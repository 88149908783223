<template>
  <CommonComponentHeader title="Архив программ" :items="breadcrumbsItems" />

  <CommonTable :head-list="listHeader" :body-list="filteredBodyList" />

  <CommonPagination
    v-if="hasShowPagination"
    :length="pagesCount"
    :current-page="page"
    @changePage="changePage"
    class="programsPage__pagination"
  />
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { CommonTable } from "@/components/NewComponents/CommonTable";
import { listHeader, listBody, statuses } from "../../constants";
import { breadcrumbsItems } from "./constants";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonPagination } from "@/components/NewComponents/CommonPagination";
import store from "@/store";

export default defineComponent({
  name: "ProgramsCreatePage",

  components: {
    CommonTable,
    CommonComponentHeader,
    CommonPagination,
  },

  setup() {
    const page = ref<number>(1);

    const pagesCount = computed(() =>
      Math.ceil((Number(degreeList.value?.length) || 0) / 10)
    );

    const hasShowPagination = computed(() => pagesCount.value > 1);

    const degreeList = computed(() => store.getters.programsStore?.listData);

    const filteredBodyList = computed(() =>
      mappedBody.value?.filter((item: any) => {
        console.log(item);
        return item[item.length - 2]?.title === statuses.archive.title;
      })
    );

    const mappedBody = computed(() =>
      degreeList.value?.map((item: any) => {
        return [
          {
            title: item.title,
          },
          {
            title: item.start_at?.slice(0, 10),
          },
          {
            title: item.end_at?.slice(0, 10),
          },
          {
            title: "3",
          },
          {
            title: item.teachers?.join(", "),
          },
          {
            title: statuses.active.title,
            hasStatus: true,
            statusColor: statuses.active.color,
          },
          {
            title: "active",
            hasAction: true,
            id: item.id,
          },
        ];
      })
    );

    const changePage = (value: number) => {
      page.value = value;
    };

    onMounted(async () => {
      await store.dispatch.programsStore.getDegreeList({
        degree_id: 4,
        pageNumber: ++page.value,
      });
    });

    return {
      listHeader,
      filteredBodyList,
      page,
      breadcrumbsItems,
      hasShowPagination,
      pagesCount,
      changePage,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
