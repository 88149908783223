<template>
  <label class="commonCheckbox">
    <input
      type="checkbox"
      class="commonCheckbox__input"
      :checked="modelValue"
      @change="toggleCheckbox"
    />
    <div
      class="commonCheckbox__box"
      :class="{ 'commonCheckbox__box-checked': modelValue }"
    >
      <img
        v-if="modelValue"
        src="./icons/Vector.svg"
        class="commonCheckbox__box__icon"
        alt="Checked"
      />
    </div>
    <span class="commonCheckbox__label">
      <slot>{{ label }}</slot>
    </span>
  </label>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommonCheckbox",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    toggleCheckbox(event: Event) {
      const target = event.target as HTMLInputElement;
      this.$emit("update:modelValue", target.checked);
    },
  },
});
</script>

<style scoped lang="stylus">
.commonCheckbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__input {
    display: none;
  }

  &__box {
    width: 16px;
    height: 16px;
    border: 1px solid $as-active-green;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s, border-color 0.2s;

    &-checked {
      background-color: $as-active-green;
      border-color: $as-active-green;
    }

    &__icon {
      width: 8.4px;
      height: 6.4px;
    }
  }

  &__label {
    margin-left: 8px;
    color: $dpo-black;
    getFontSmall()
    padding: 8px 0;
    line-height: 16px;
  }
}
</style>
