import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7055be7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "commonTable__head" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "commonTable__content"
}
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonDropdownActions = _resolveComponent("CommonDropdownActions")!
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createBlock(_component_v_table, null, {
    default: _withCtx(() => [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headList, (item, i) => {
            return (_openBlock(), _createElementBlock("th", {
              class: "text-left commonTable__headItem",
              key: `head-item-${i}`
            }, _toDisplayString(item), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyList, (item, i) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: `body-item-${i}`
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (ceil, index) => {
              return (_openBlock(), _createElementBlock("td", {
                key: `ceil-${i}-${index}`,
                class: "commonTable__bodyItem"
              }, [
                (!ceil.hasStatus && !ceil.hasAction)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(ceil.title || "-"), 1))
                  : _createCommentVNode("", true),
                (ceil.hasStatus)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", {
                        class: "commonTable__circle",
                        style: _normalizeStyle(`background-color: ${ceil.statusColor}`)
                      }, null, 4),
                      _createTextVNode(" " + _toDisplayString(ceil.title), 1)
                    ]))
                  : _createCommentVNode("", true),
                (ceil.hasAction)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_CommonDropdownActions, {
                        onEdit: ($event: any) => (_ctx.$emit('edit', ceil.id)),
                        onArchive: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('archive'))),
                        onView: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('view')))
                      }, null, 8, ["onEdit"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}