<template>
  <img src="./down.svg" class="custom-icon" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomDown",
});
</script>

<style scoped>
.custom-icon {
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
}
</style>
