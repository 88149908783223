import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1f4bd5e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "programsCreatePage" }
const _hoisted_2 = { class: "programsCreatePage__leftSide" }
const _hoisted_3 = { class: "programsCreatePage__rightSide" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonComponentHeader = _resolveComponent("CommonComponentHeader")!
  const _component_FormAbout = _resolveComponent("FormAbout")!
  const _component_FormGoals = _resolveComponent("FormGoals")!
  const _component_FormSecondGoals = _resolveComponent("FormSecondGoals")!
  const _component_FormAdvantages = _resolveComponent("FormAdvantages")!
  const _component_FormHowLearnGoing = _resolveComponent("FormHowLearnGoing")!
  const _component_FormPlan = _resolveComponent("FormPlan")!
  const _component_FormReceptionCompany = _resolveComponent("FormReceptionCompany")!
  const _component_FormDetails = _resolveComponent("FormDetails")!
  const _component_FormSkills = _resolveComponent("FormSkills")!
  const _component_FormCareer = _resolveComponent("FormCareer")!
  const _component_FormTeachers = _resolveComponent("FormTeachers")!
  const _component_FormCertificates = _resolveComponent("FormCertificates")!
  const _component_FormReviews = _resolveComponent("FormReviews")!
  const _component_FormFaq = _resolveComponent("FormFaq")!
  const _component_FormDocs = _resolveComponent("FormDocs")!
  const _component_FormContacts = _resolveComponent("FormContacts")!
  const _component_FormDate = _resolveComponent("FormDate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CommonComponentHeader, {
      title: _ctx.headerTitle,
      items: _ctx.breadcrumbsItems
    }, null, 8, ["title", "items"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FormAbout, {
          onUpdateValues: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel, $event))),
          form: _ctx.formModel
        }, null, 8, ["form"]),
        _createVNode(_component_FormGoals, {
          onUpdateValues: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel.goal, $event))),
          form: _ctx.formModel.goal
        }, null, 8, ["form"]),
        _createVNode(_component_FormSecondGoals, {
          onUpdateValues: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel.goal, $event))),
          form: _ctx.formModel.goal
        }, null, 8, ["form"]),
        _createVNode(_component_FormAdvantages, {
          onUpdateValues: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel.advantages, $event))),
          form: _ctx.formModel.advantages
        }, null, 8, ["form"]),
        _createVNode(_component_FormHowLearnGoing, {
          onUpdateValues: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel.how_learn_going, $event))),
          form: _ctx.formModel.how_learn_going
        }, null, 8, ["form"]),
        _createVNode(_component_FormPlan, {
          onUpdateValues: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel.plan, $event))),
          form: _ctx.formModel.plan
        }, null, 8, ["form"]),
        _createVNode(_component_FormReceptionCompany, {
          onUpdateValues: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel.reception_company, $event))),
          form: _ctx.formModel.reception_company
        }, null, 8, ["form"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FormDetails, {
          onUpdateValues: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel, $event))),
          form: _ctx.formModel
        }, null, 8, ["form"]),
        _createVNode(_component_FormSkills, {
          onUpdateValues: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel, $event))),
          form: _ctx.formModel
        }, null, 8, ["form"]),
        _createVNode(_component_FormCareer, {
          onUpdateValues: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel, $event))),
          form: _ctx.formModel
        }, null, 8, ["form"]),
        _createVNode(_component_FormTeachers, {
          onUpdateValues: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel, $event))),
          form: _ctx.formModel
        }, null, 8, ["form"]),
        _createVNode(_component_FormCertificates, {
          onUpdateValues: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel.certificate, $event))),
          form: _ctx.formModel.certificate
        }, null, 8, ["form"]),
        _createVNode(_component_FormReviews, {
          onUpdateValues: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel.reviews, $event))),
          form: _ctx.formModel.reviews
        }, null, 8, ["form"]),
        _createVNode(_component_FormFaq, {
          onUpdateValues: _cache[13] || (_cache[13] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel.faq, $event))),
          form: _ctx.formModel.faq
        }, null, 8, ["form"]),
        _createVNode(_component_FormDocs, {
          onUpdateValues: _cache[14] || (_cache[14] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel.documents, $event))),
          form: _ctx.formModel.documents
        }, null, 8, ["form"]),
        _createVNode(_component_FormContacts, {
          onUpdateValues: _cache[15] || (_cache[15] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel.contacts, $event))),
          form: _ctx.formModel.contacts
        }, null, 8, ["form"]),
        _createVNode(_component_FormDate, {
          onUpdateValues: _cache[16] || (_cache[16] = ($event: any) => (_ctx.onUpdateValues(_ctx.formModel, $event))),
          form: _ctx.formModel
        }, null, 8, ["form"])
      ])
    ])
  ], 64))
}