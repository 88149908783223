<template>
  <header class="header dpo">
    <div class="header__wrap">
      <div class="header__logoContainer">
        <a href="/">
          <svg class="header__logo">
            <use xlink:href="#logo-icon--sprite" />
          </svg>
        </a>
      </div>

      <div class="header__content">
        <div class="header__inputContainer">
          <CommonInput class="header__input" placeholder="Поиск">
            <template #prefix>
              <svg class="header__searchIcon">
                <use xlink:href="#search-icon--sprite" />
              </svg>
            </template>
          </CommonInput>
        </div>

        <div class="header__personContainer">
          <CommonButtonPrimary @click="openSignInModal" v-if="!userFullName">
            Войти
          </CommonButtonPrimary>

          <div class="header__person bold-text" v-else>{{ userFullName }}</div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { useVfm } from "vue-final-modal";
import { RouteName } from "@/constants";
import { menu } from "./constants";
import store from "@/store";

import "@/assets/img/icons/logo-icon.svg?sprite";

export default defineComponent({
  name: "LayoutHeader",
  components: {
    CommonInput,
    CommonButtonPrimary,
  },

  setup() {
    const vfm = useVfm();

    const me = computed(() => store.getters.userStore.me);

    const userFullName = computed(() => me.value?.fullname);

    const isShownNotifications = ref(false);

    const openSignInModal = () => vfm.open("signInModal");

    onMounted(() => {
      store.dispatch.userStore.getMe();
    });

    return {
      userFullName,
      menu,
      RouteName,
      isShownNotifications,
      openSignInModal,
    };
  },
});
</script>

<style lang="stylus" scoped>
.header {
  position: sticky;
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: $color-white;
  border-bottom: 1px solid $magistracy-medium-grey;

  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }

  &__logo {
    height: 50px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1vw;
    flex-grow: 1;
  }

  &__inputContainer {
    flex-grow: 1;
  }

  &__input {
    max-width: 620px;
  }

  &__searchIcon {
    width: 16px;
    height: 16px;
  }

  &__personContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    flex-grow: 1;
    justify-content: flex-end;
  }

  &__person {
    font-weight: bold;
  }

  +mediaTablet() {
    padding: 20px;

    &__logo {
      width: 15vw;
    }

    &__icon {
      width: 30px;
      height: 30px;
    }
  }

  +mediaGiant() {
    &__link {
      font-size: 20px;
    }
  }

  @media (min-width:1400px) {
    &__wrap {
      max-width: 1400px;
    }
  }
}
</style>
