<template>
  <CommonComponentHeader :title="headerTitle" :items="breadcrumbsItems" />

  <div class="programsCreatePage">
    <div class="programsCreatePage__leftSide">
      <FormAbout
        @update-values="onUpdateValues(formModel, $event)"
        :form="formModel"
      />

      <FormGoals
        @update-values="onUpdateValues(formModel.goal, $event)"
        :form="formModel.goal"
      />

      <FormSecondGoals
        @update-values="onUpdateValues(formModel.goal, $event)"
        :form="formModel.goal"
      />

      <FormAdvantages
        @update-values="onUpdateValues(formModel.advantages, $event)"
        :form="formModel.advantages"
      />

      <FormHowLearnGoing
        @update-values="onUpdateValues(formModel.how_learn_going, $event)"
        :form="formModel.how_learn_going"
      />

      <FormPlan
        @update-values="onUpdateValues(formModel.plan, $event)"
        :form="formModel.plan"
      />

      <FormReceptionCompany
        @update-values="onUpdateValues(formModel.reception_company, $event)"
        :form="formModel.reception_company"
      />
    </div>

    <div class="programsCreatePage__rightSide">
      <FormDetails
        @update-values="onUpdateValues(formModel, $event)"
        :form="formModel"
      />

      <FormSkills
        @update-values="onUpdateValues(formModel, $event)"
        :form="formModel"
      />

      <FormCareer
        @update-values="onUpdateValues(formModel, $event)"
        :form="formModel"
      />

      <FormTeachers
        @update-values="onUpdateValues(formModel, $event)"
        :form="formModel"
      />

      <FormCertificates
        @update-values="onUpdateValues(formModel.certificate, $event)"
        :form="formModel.certificate"
      />

      <FormReviews
        @update-values="onUpdateValues(formModel.reviews, $event)"
        :form="formModel.reviews"
      />

      <FormFaq
        @update-values="onUpdateValues(formModel.faq, $event)"
        :form="formModel.faq"
      />

      <FormDocs
        @update-values="onUpdateValues(formModel.documents, $event)"
        :form="formModel.documents"
      />

      <FormContacts
        @update-values="onUpdateValues(formModel.contacts, $event)"
        :form="formModel.contacts"
      />

      <FormDate
        @update-values="onUpdateValues(formModel, $event)"
        :form="formModel"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  computed,
  watchEffect,
  onMounted,
} from "vue";
import { FormAbout } from "./components/FormAbout";
import { FormGoals } from "./components/FormGoals";
import { FormSecondGoals } from "./components/FormSecondGoals";
import { FormAdvantages } from "./components/FormAdvantages";
import { FormHowLearnGoing } from "./components/FormHowLearnGoing";
import { FormPlan } from "./components/FormPlan";
import { FormReceptionCompany } from "./components/FormReceptionCompany";
import { FormDetails } from "./components/FormDetails";
import { FormSkills } from "./components/FormSkills";
import { FormCareer } from "./components/FormCareer";
import { FormTeachers } from "./components/FormTeachers";
import { FormCertificates } from "./components/FormCertificates";
import { FormReviews } from "./components/FormReviews";
import { FormFaq } from "./components/FormFaq";
import { FormDocs } from "./components/FormDocs";
import { FormContacts } from "./components/FormContacts";
import { FormDate } from "./components/FormDate";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import store from "@/store";
import { useRouter, useRoute } from "vue-router";

import "@/assets/img/icons/plus-curator.svg?sprite";
import "@/assets/img/icons/close-icon.svg?sprite";
import Cookies from "js-cookie";
import { RouteName } from "@/constants";

export default defineComponent({
  name: "ProgramsCreatePage",

  components: {
    CommonComponentHeader,
    FormAbout,
    FormGoals,
    FormSecondGoals,
    FormAdvantages,
    FormHowLearnGoing,
    FormPlan,
    FormReceptionCompany,
    FormDetails,
    FormSkills,
    FormCareer,
    FormTeachers,
    FormCertificates,
    FormReviews,
    FormFaq,
    FormDocs,
    FormContacts,
    FormDate,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const formModel = reactive<any>({
      title: "",
      desc: {
        header: "",
        desc: "",
      },
      for_whom: "",
      learning_format: "",
      form: "",
      cost: undefined,
      number_of_places: undefined,
      learning_time: {
        hours: 0,
        weeks: 0,
        years: 0,
      },
      goal: {
        goal_text: "",
        list1: [],
        goal2: {
          text: "",
          list: [],
        },
      },
      advantages: {
        main: [],
        about: [],
      },
      skills: [],
      career: [],
      how_learn_going: [
        {
          title: "",
          desc: "",
        },
      ],
      teachers: [],
      plan: {
        text: "",
        list: [
          {
            period: {
              name: "",
              date: "",
            },
            disciplines: [],
          },
        ],
      },
      certificate: {
        desc: "",
        type: "",
        title: "",
        list: [],
        details: "",
      },
      media: {
        certificate_image: "" as any,
        manager_photo: "" as any,
        banner1: "" as any,
      },
      reviews: [
        {
          name: "",
          position: "",
          text: "",
          video: "",
        },
      ],
      faq: [
        {
          question: "",
          answer: "",
        },
      ],
      reception_company: {
        start_reception_documents: "",
        desc: [
          {
            header: "",
            text: "",
          },
        ],
        reception_bar_1: [
          {
            title: "",
            list: [],
          },
        ],
        reception_bar_2: [
          {
            title: "",
            date: "",
          },
        ],
      },
      documents: [
        {
          title: "",
          list: [],
        },
      ],
      contacts: {
        manager: {
          fullname: "",
          desc: "",
        },
        works_time: "",
        emails: [],
        phones: [],
        address: "",
      },
      start_at: "",
      end_at: "",
      status: "",
      is_nmo: false as boolean | number,
    });

    const program = computed(() =>
      store.getters.programsStore.listData?.find(
        (item: any) => Number(item.id) === Number(programId.value)
      )
    );

    const programId = computed(() => route?.query?.programId);

    const breadcrumbsItems = computed(() => [
      {
        title: "Программы",
        to: {
          name: RouteName.CURATOR_PROGRAMS,
        },
      },
      ...[
        programId.value
          ? {
              title: program.value?.title,
              to: {
                name: RouteName.CURATOR_PROGRAMS_EDIT,
              },
            }
          : {
              title: "Создание новой программы",
              to: {
                name: RouteName.CURATOR_PROGRAMS_CREATE,
              },
            },
      ],
    ]);

    const headerTitle = computed(() =>
      programId.value ? program.value?.title : "Создание новой программы"
    );

    const onUpdateValues = (obj: any, value: any) => {
      Object.assign(obj, value);
    };

    watchEffect(() => {
      if (programId.value) {
        Object.keys(formModel).forEach((key: any) => {
          if (program.value && key in program.value && program.value[key]) {
            if (
              typeof formModel[key] === "string" ||
              typeof formModel[key] === "number"
            ) {
              formModel[key] = program.value[key];
            } else if (Array.isArray(formModel[key])) {
              formModel[key].concat(program.value[key]);
            } else if (typeof formModel[key] === "object") {
              Object.assign(formModel[key], program.value[key]);
            }
          }
        });
        Object.assign(formModel, program.value);

        formModel.start_at = formModel.start_at.slice(0, 10);
        formModel.end_at = formModel.end_at.slice(0, 10);
      }
    });

    onMounted(() => {
      if (programId.value) {
        router.push({
          name: RouteName.CURATOR_PROGRAMS_EDIT,
          query: { programId: programId.value },
        });
      }
    });

    return {
      formModel,
      breadcrumbsItems,
      program,
      headerTitle,
      onUpdateValues,
    };
  },
});
</script>

<style lang="stylus" scoped>
:deep {
  .programs-input-box {
    display: flex;
    flex-direction: column;
    gap: 9.5px;
  }

  .programs-plus {
    width: 28px;
    height: 28px;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  .programs-delete {
    padding-bottom: 16px;
    border-bottom: 1px solid $magistracy-medium-grey;
    font-size: 16px;

    &:hover {
      cursor: pointer;
      color: $as-red-aspirant;
    }
  }

  .programs-close {
    width: 12px;
    height: 12px;
    fill: $as-dark-grey;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  .programs-label {
    color: $as-dark-grey;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .programs-second-title {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
  }

  .programs-date {
    max-width: 160px;
  }
}
.programsCreatePage {
  padding: 20px;
  display: flex;
  gap: 18px;

  &__leftSide,
  &__rightSide {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
  }
}
</style>
