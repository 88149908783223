<template>
  <v-table>
    <thead>
      <tr class="commonTable__head">
        <th
          class="text-left commonTable__headItem"
          v-for="(item, i) in headList"
          :key="`head-item-${i}`"
        >
          {{ item }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(item, i) in bodyList" :key="`body-item-${i}`">
        <td
          v-for="(ceil, index) in item"
          :key="`ceil-${i}-${index}`"
          class="commonTable__bodyItem"
        >
          <span v-if="!ceil.hasStatus && !ceil.hasAction">
            {{ ceil.title || "-" }}
          </span>

          <div v-if="ceil.hasStatus" class="commonTable__content">
            <div
              class="commonTable__circle"
              :style="`background-color: ${ceil.statusColor}`"
            />
            {{ ceil.title }}
          </div>

          <div v-if="ceil.hasAction">
            <CommonDropdownActions
              @edit="$emit('edit', ceil.id)"
              @archive="$emit('archive')"
              @view="$emit('view')"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { CommonTableProps } from "./types";
import { CommonDropdownActions } from "../CommonDropdownActions";

export default defineComponent({
  name: "CommonTable",

  components: {
    CommonDropdownActions,
  },

  emits: ["edit", "archive", "view"],

  props: {
    headList: {
      type: Array as PropType<CommonTableProps["headList"]>,
      required: true,
    },
    bodyList: {
      type: Array as PropType<CommonTableProps["bodyList"]>,
      required: true,
    },
  },
});
</script>

<style lang="stylus" scoped>
.commonTable {
  &__head {
    background-color: $as-extra-light-bg;
  }

  &__headItem {
    color: $grey-sistems;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__bodyItem {
    font-weight: 500;
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__circle {
    width: 8px;
    height: 8px;
    border-radius: 20px;
  }
}
</style>
