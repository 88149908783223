<template>
  <CommonFormContainer title="Структура образовательной программы">
    <span class="programs-label"> Длительность обучения </span>

    <div class="learning-duration">
      <CommonSelect
        class="learning-duration__item"
        v-model="periodType"
        :options="['Годы', 'Часы', 'Недели']"
        placeholder="Выберите из списка"
        :multiple="false"
        @update:model-value="clearLearningTime"
      />

      <CommonInput
        v-if="periodType === 'Часы'"
        class="learning-duration__item"
        v-model="formModel.learning_time.hours"
        placeholder="Введите текст"
        @update:model-value="$emit('updateValues', formModel)"
      />

      <CommonInput
        v-else-if="periodType === 'Недели'"
        class="learning-duration__item"
        v-model="formModel.learning_time.weeks"
        placeholder="Введите текст"
        @update:model-value="$emit('updateValues', formModel)"
      />

      <CommonInput
        v-else
        class="learning-duration__item"
        v-model="formModel.learning_time.years"
        placeholder="Введите текст"
        @update:model-value="$emit('updateValues', formModel)"
      />
    </div>

    <CommonSelect
      v-model="formModel.learning_format"
      :options="['1', '2', '3']"
      placeholder="Выберите из списка"
      :multiple="false"
    >
      Формат обучения
    </CommonSelect>

    <CommonSelect
      v-model="formModel.form"
      :options="['1', '2', '3']"
      placeholder="Выберите из списка"
      :multiple="false"
    >
      Форма обучения
    </CommonSelect>

    <CommonInput
      v-model="formModel.cost"
      placeholder="Стоимость"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Стоимость, руб
    </CommonInput>

    <CommonInput
      v-model="formModel.number_of_places"
      placeholder="Количество мест"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Количество мест
    </CommonInput>

    <CommonCheckbox
      v-model="formModel.is_nmo"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Баллы НМО
    </CommonCheckbox>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";
import { CommonCheckbox } from "@/components/NewComponents/CommonCheckbox";

export default defineComponent({
  name: "FormDetails",

  components: {
    CommonFormContainer,
    CommonInput,
    CommonSelect,
    CommonCheckbox,
  },

  props: ["form"],

  setup(props) {
    const periodType = ref("Годы");

    const formModel = reactive({
      learning_format: "",
      form: "",
      cost: undefined,
      number_of_places: undefined,
      learning_time: {
        hours: 0,
        weeks: 0,
        years: 0,
      },
      is_nmo: false,
    });

    const onUpdateSelect = (disciplinesArr: any, value: any) => {
      disciplinesArr = value;
    };

    const clearLearningTime = () => {
      formModel.learning_time.hours = 0;
      formModel.learning_time.weeks = 0;
      formModel.learning_time.years = 0;
    };

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      periodType,
      formModel,
      onUpdateSelect,
      clearLearningTime,
    };
  },
});
</script>

<style lang="stylus" scoped>
.learning-duration {
  display: flex;
  align-items: flex-end;
  gap: 8px;

  &__item {
    width: fit-content;
    max-width: 140px;
  }
}
</style>
