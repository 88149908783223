<template>
  <VueFinalModal
    :class="{ dpo: hasDpo }"
    v-model="test"
    name="ModalTrajectory"
    modal-id="ModalTrajectory"
    :zIndexFn="() => 1000"
  >
    <div class="modalTrajectory">
      <div class="modalTrajectory__titleBox">
        <h6 class="modalTrajectory__title">Пожалуйста, ответьте на вопросы</h6>

        <p>Это поможет настроить вашу индивидуальную траекторию обучения</p>
      </div>

      <div class="modalTrajectory__content">
        <div
          class="modalTrajectory__question"
          v-for="(question, index) in questionsList"
          :key="question.label"
        >
          <div class="modalTrajectory__questionTitle">
            {{ question.label }}
          </div>

          <div
            class="modalTrajectory__radioBox"
            v-for="answer in question.answers"
            :key="answer"
          >
            <input
              type="radio"
              :id="`question-${index}`"
              :name="`question-${index}`"
              class="modalTrajectory__radio"
            />
            <label :for="`question-${index}`">{{ answer }}</label>
          </div>
        </div>
      </div>

      <CommonButtonPrimary class="modalTrajectory__btn" @click="goToTest">
        Отправить ответы
      </CommonButtonPrimary>
    </div>
  </VueFinalModal>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { VueFinalModal } from "vue-final-modal";
import { questionsList } from "./constants";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";
import { useVfm } from "vue-final-modal";

export default defineComponent({
  name: "ModalTrajectory",

  components: {
    VueFinalModal,
    CommonButtonPrimary,
  },

  setup() {
    const vfm = useVfm();
    const route = useRoute();

    const test = ref<boolean>(false);

    const hasDpo = computed(
      () => route.matched[0]?.name === RouteName.DPO || route.path === "/"
    );

    const goToTest = () => {
      vfm.close("ModalTrajectory");
      vfm.open("ModalGoToEducation");
    };

    return {
      questionsList,
      test,
      hasDpo,
      goToTest,
    };
  },
});
</script>

<style lang="stylus">
.vfm {
  position: absolute;
  display: flex;
  justify-content: center;
  width: calc(100% + 100px);
  height: calc(100% + 100px);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  &__content {
    position: relative;
    top: 0;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    opacity: .8;
    z-index: -1;
    background: $dpo-dark-grey;
  }
}
</style>

<style lang="stylus" scoped>
.modalTrajectory {
  position: relative;
  top: 60px;
  margin-bottom: 30%;
  width: 100%;
  max-width: 95vw;
  padding: 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  box-shadow: 0 4px 12px 0 rgba(25, 135, 84, 0.25);
  overflow: hidden;
  background-color: $color-white;

  &__titleBox {
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__question {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__radioBox {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__btn {
    width: fit-content;
    margin: 0 auto;
  }

  +mediaTablet() {
    max-width: 60vw;
    padding: 64px;
    gap: 3vw;
  }
}
</style>
