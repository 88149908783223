<template>
  <v-breadcrumbs :items="items" divider="&rang;" class="commonBreadcrumbs">
    <template v-slot:divider>
      <svg class="arrow">
        <use xlink:href="#arrow-breadcrumb-icon--sprite" />
      </svg>
    </template>
  </v-breadcrumbs>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

import "@/assets/img/icons/arrow-breadcrumb-icon.svg?sprite";

export default defineComponent({
  name: "CommonBreadcrumbs",

  props: ["items"],

  components: {},

  setup() {
    return {};
  },
});
</script>

<style lang="stylus" scoped>
:deep {
  .v-breadcrumbs-divider {
    display: flex;
    align-items: center;
  }
}

.commonBreadcrumbs {
  padding: 0;
}

.arrow {
  width: 16px;
  height: 16px;
}
</style>
