<template>
  <!-- <v-pagination :length="length"></v-pagination> -->
  <div class="commonPagination">
    <div class="commonPagination__arrowsBox">
      <svg
        :class="[
          'arrow-wrap',
          'arrow_left',
          { 'arrow-wrap__active': isActiveArrowLeft },
        ]"
        @click="goToPrevPage"
      >
        <use
          :class="['arrow', { arrow__active: isActiveArrowLeft }]"
          xlink:href="#arrow-right-icon--sprite"
        />
      </svg>

      <svg
        :class="['arrow-wrap', { 'arrow-wrap__active': isActiveArrowRight }]"
        @click="goToNextPage"
      >
        <use
          :class="['arrow', { arrow__active: isActiveArrowRight }]"
          xlink:href="#arrow-right-icon--sprite"
        />
      </svg>
    </div>

    <div class="commonPagination__countBox">
      Страница {{ currentPage }} из {{ length }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

import "@/assets/img/icons/arrow-right-icon.svg?sprite";

export default defineComponent({
  name: "CommonPagination",

  props: {
    length: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 1,
      required: true,
    },
  },

  emits: ["changePage"],

  components: {},

  setup(props, { emit }) {
    const isActiveArrowLeft = computed(() => props.currentPage > 1);
    const isActiveArrowRight = computed(() => props.currentPage < props.length);

    const goToPrevPage = () => {
      if (isActiveArrowLeft.value) {
        emit("changePage", props.currentPage - 1);
      }
    };

    const goToNextPage = () => {
      if (isActiveArrowRight.value) {
        emit("changePage", props.currentPage + 1);
      }
    };

    return {
      isActiveArrowLeft,
      isActiveArrowRight,
      goToPrevPage,
      goToNextPage,
    };
  },
});
</script>

<style lang="stylus" scoped>
.commonPagination {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 18px;
  background-color: $color-white;
  user-select: none;

  &__arrowsBox {
    display: flex;
    gap: 16px;
  }

  &__countBox {
    color: $as-grey-system;
  }
}

.arrow {
  stroke: $as-grey-system;

  &-wrap {
    width: 24px;
    height: 24px;

    &__active {
      &:hover {
        cursor: pointer;
        border-radius: 10px;
        opacity: .4;
      }
    }
  }

  &_left {
    transform: rotate(180deg);
  }

  &__active {
    stroke: $as-active-green;
  }
}
</style>
