<template>
  <CommonFormContainer title="Структура образовательной программы">
    <CommonTextEditor
      v-model="formModel.text"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Описание
    </CommonTextEditor>

    <span class="programs-second-title"> Периоды </span>

    <div
      class="programs-input-box"
      v-for="(item, i) in formModel.list"
      :key="`plan-${i}`"
    >
      <CommonInput
        v-model="formModel.list[i].period.name"
        placeholder="Введите текст"
        @update:model-value="$emit('updateValues', formModel)"
      >
        Название периода
      </CommonInput>

      <CommonInput
        v-model="formModel.list[i].period.date"
        placeholder="Введите текст"
        @update:model-value="$emit('updateValues', formModel)"
      >
        Даты
      </CommonInput>

      <CommonSelect
        v-model="formModel.list[i].disciplines"
        :options="mappedDisciplines"
        placeholder="Выберите из списка"
        @update:modelValue="
          $emit('updateValues', formModel);
          onUpdateSelect(formModel.list[i].disciplines, $event);
        "
      >
        Дисциплины
      </CommonSelect>

      <div
        class="programs-delete"
        @click="
          $emit('updateValues', formModel);
          removeItem(i);
        "
      >
        Удалить период
      </div>
    </div>

    <svg
      class="programs-plus"
      @click="
        pushNewItem();
        $emit('updateValues', formModel);
      "
    >
      <use xlink:href="#plus-curator--sprite" />
    </svg>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, watch, onMounted } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonTextEditor } from "@/components/NewComponents/CommonTextEditor";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";
import store from "@/store";

export default defineComponent({
  name: "FormPlan",

  components: {
    CommonFormContainer,
    CommonInput,
    CommonTextEditor,
    CommonSelect,
  },

  props: ["form"],

  setup(props) {
    const formModel = reactive({
      text: "",
      list: [
        {
          period: {
            name: "",
            date: "",
          },
          disciplines: [],
        },
      ],
    });

    const disciplinesList = computed(
      () => store.getters.programsStore?.disciplinesList
    );

    const mappedDisciplines = computed(
      () =>
        disciplinesList.value?.map((item: any) => {
          return item.title;
        }) || []
    );

    const pushNewItem = () => {
      formModel.list.push({
        period: {
          name: "",
          date: "",
        },
        disciplines: [],
      });
    };

    const removeItem = (index: number) => {
      formModel.list.splice(index, 1);
    };

    const onUpdateSelect = (disciplinesArr: any, value: any) => {
      disciplinesArr = value;
    };

    watch(
      () => props.form,
      (newValue: any) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    onMounted(async () => {
      await store.dispatch.programsStore.getDisciplines({
        degree_id: 4,
      });
    });

    return {
      mappedDisciplines,
      formModel,
      pushNewItem,
      removeItem,
      onUpdateSelect,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
