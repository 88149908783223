<template>
  <div class="mainContainer">
    <h2 class="mainContainer__title">Общая информация</h2>

    <div class="mainContainer__stats">
      <div
        class="mainContainer__statBlock"
        v-for="(item, i) in firstStats"
        :key="item.label"
      >
        <div class="mainContainer__labelBlock">
          <svg class="mainContainer__icon">
            <use :href="`#first-stats-icon-${i + 1}--sprite`" />
          </svg>

          <span class="mainContainer__text" v-html="item.label" />
        </div>

        <span class="mainContainer__value">{{ item.value }}</span>
      </div>
    </div>

    <div class="mainContainer__secondTitle">Статистика по успеваемости</div>

    <div class="mainContainer__stats">
      <div
        class="mainContainer__statBlock"
        v-for="(item, i) in secondStats"
        :key="item.label"
      >
        <div class="mainContainer__labelBlock">
          <svg class="mainContainer__icon">
            <use :href="`#first-stats-icon-${i + 1}--sprite`" />
          </svg>

          <span class="mainContainer__text">{{ item.label }}</span>
        </div>

        <span class="mainContainer__value">{{ item.value }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { firstStats, secondStats } from "./constants";

import "@/assets/img/icons/first-stats-icon-1.svg?sprite";
import "@/assets/img/icons/first-stats-icon-2.svg?sprite";
import "@/assets/img/icons/first-stats-icon-3.svg?sprite";
import "@/assets/img/icons/second-stats-icon-1.svg?sprite";
import "@/assets/img/icons/second-stats-icon-2.svg?sprite";

export default defineComponent({
  name: "MainContainer",

  components: {},

  setup() {
    return {
      firstStats,
      secondStats,
    };
  },
});
</script>

<style lang="stylus" scoped>
.mainContainer {
  padding: 24px;
  background-color: $color-white;
  border: 1px solid $magistracy-medium-grey;
  border-radius: 10px;

  &__title {
    margin-bottom: 32px;
  }

  &__secondTitle {
    padding: 14px;
    margin-bottom: 16px;
    width: 100%;
    color: $as-active-green;
    text-align: center;
    background-color: $as-green-light;
    border-radius: 10px;
    getFontText();
  }

  &__stats {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 40px;
  }

  &__statBlock {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    border: 1px solid $magistracy-medium-grey;
    border-radius: 10px;
  }

  &__labelBlock {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &__text {
    text-transform: uppercase;
    font-weight: 500;
    getFontText();
  }

  &__value {
    font-size: 40px;
    color: $as-active-green;
  }

  &__icon {
    width: 40px;
    height: 40px;
  }
}
</style>
