import { RouteName } from "@/constants";

export const navList = [
  {
    id: 1,
    label: "Главная",
    pathName: RouteName.CURATOR_GENERAL,
  },
  {
    id: 2,
    label: "Программы",
    pathName: null,
    subNav: [
      {
        id: 1,
        label: "Действующие программы",
        pathName: RouteName.CURATOR_PROGRAMS,
      },
      {
        id: 2,
        label: "Архив программ",
        pathName: RouteName.CURATOR_PROGRAMS_ARCHIVE,
      },
      {
        id: 3,
        label: "Календарный план",
        pathName: RouteName.CURATOR_PROGRAMS_ARCHIVE,
      },
    ],
  },
  {
    id: 3,
    label: "Группы",
    pathName: RouteName.HOME,
  },
  {
    id: 4,
    label: "Пользователи",
    pathName: RouteName.HOME,
  },
  {
    id: 5,
    label: "Расписание",
    pathName: RouteName.HOME,
  },
  {
    id: 6,
    label: "Библиотека",
    pathName: RouteName.HOME,
  },
];

export const actionsList = [
  {
    id: 1,
    label: "Настройки",
  },
  {
    id: 2,
    label: "Выйти",
  },
];
