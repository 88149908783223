<template>
  <LayoutHomepage
    v-if="hasProgramsList"
    is-higher
    :emails-list="hasDpo ? emailsDpoList : emailsList"
    :phones-list="hasDpo ? phonesDpoList : phonesList"
  >
    <CommonIntro />

    <div class="programsSort" v-if="hasDpo">
      <div class="programsSort__itemsBox">
        <div
          :class="[
            'programsSort__item',
            {
              programsSort__item_active: item.value === sortValue,
              programsSort__item_disabled: item.value === '-',
            },
          ]"
          v-for="item in sortList1"
          :key="item.title"
          @click="setSortValue(item.value, item.name)"
        >
          {{ item.title }}
        </div>
      </div>

      <div class="programsSort__itemsBox">
        <div
          :class="[
            'programsSort__item',
            {
              programsSort__item_active: item.value === +sortValue,
            },
          ]"
          v-for="item in sortList2"
          :key="item.title"
          @click="setSortValue(item.value, item.name)"
        >
          {{ item.title }}
        </div>
      </div>

      <div class="programsSort__itemsBox">
        <div
          :class="[
            'programsSort__item',
            {
              programsSort__item_active: item.value === +sortValue,
            },
          ]"
          v-for="item in sortList3"
          :key="item.title"
          @click="setSortValue(item.value, item.name)"
        >
          {{ item.title }}
        </div>
      </div>

      <div class="programsSort__itemsBox">
        <div
          :class="[
            'programsSort__item',
            {
              programsSort__item_active: item.value === sortValue,
            },
          ]"
          v-for="item in sortList4"
          :key="item.title"
          @click="setSortValue(item.value, item.name)"
        >
          {{ item.title }}
        </div>

        <div
          class="programsSort__item programsSort__itemClear"
          @click="clearFilters"
        >
          Сбросить фильтры
        </div>
      </div>

      <CommonInput
        v-model="searchValue"
        class="programsSort__search"
        placeholder="Быстрый поиск по программам"
        @input="handleDebounceSearch"
      >
        <template #suffix>
          <svg class="programsSort__searchIcon">
            <use xlink:href="#search-icon--sprite" />
          </svg>
        </template>
      </CommonInput>
    </div>

    <!-- {{ programsList }} -->

    <div class="programsListWrap">
      <div class="programsList">
        <div
          class="programsList__item"
          v-for="program in programsList"
          :key="program?.id"
        >
          <div class="programsList__imgBox">
            <div class="programsList__imgWrap" />

            <img
              class="programsList__img"
              :src="program?.media?.banner1?.url"
            />

            <div v-if="hasDpo" class="programsList__imgContent">
              <div class="programsList__nmo" v-if="program?.is_nmo_balls">
                Баллы НМО
              </div>
            </div>
          </div>

          <h6 class="programsList__title">{{ program?.title }}</h6>

          <div class="programsList__tabsList">
            <div class="programsList__listItem">
              {{ learningTime(program?.learning_time) }}
            </div>
            <div class="programsList__listItem">
              {{ learningWeeks(program?.learning_time) }}
            </div>
            <div class="programsList__listItem">
              {{ program?.form }}
            </div>
          </div>

          <div class="form-submit dpo">
            <CommonButtonPrimary
              class="programsList__btn"
              type="submit"
              @click="goToProgram(program.id)"
            >
              Подробнее
            </CommonButtonPrimary>
          </div>
        </div>
      </div>

      <button
        v-if="pageCount < programsListMeta?.last_page"
        class="programsList__moreButton"
        @click="toggleMore"
      >
        <span class="programsList__moreText">Больше</span>

        <svg class="programsList__arrowMore">
          <use xlink:href="#black-arrow-bottom-icon--sprite" />
        </svg>
      </button>
    </div>

    <CommonApplication :degree_id="degreeId" />
  </LayoutHomepage>

  <router-view v-else />
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { LayoutHomepage } from "@/layouts/LayoutHomepage";
import { CommonApplication } from "./components/CommonApplication";
import { CommonIntro } from "./components/CommonIntro";
import { CommonInput } from "@/components/CommonInput";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import {
  homeNavList,
  sortList1,
  sortList2,
  sortList3,
  sortList4,
  emailsList,
  emailsDpoList,
  phonesList,
  phonesDpoList,
} from "./constants";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";
import store from "@/store";
import debounce from "debounce";

import "@/assets/img/icons/search-icon.svg?sprite";
import "@/assets/img/icons/black-arrow-bottom-icon.svg?sprite";

export default defineComponent({
  name: "HomeProgramsListPage",

  components: {
    LayoutHomepage,
    CommonApplication,
    CommonIntro,
    CommonInput,
    CommonButtonPrimary,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const hasAspirant = computed(
      () => route?.name === RouteName.COURSES_ASPIRANT
    );
    const hasDpo = computed(() => route?.name === RouteName.COURSES_DPO);

    const hasProgramsList = computed(() => !route?.params?.id);

    const sortValue = ref("");
    const sortName = ref("");

    const searchValue = ref("");

    const pageCount = ref(1);

    const programsList = ref<any>([]);

    const degreeList = computed(() => {
      return store.getters.programsStore?.listData;
    });

    const programsListMeta = computed(
      () => store.getters.programsStore?.programsListMeta
    );

    const bodyStyleName = computed(() => {
      if (hasAspirant.value) {
        return "aspirant";
      }
      return "courses";
    });

    const degreeId = computed(() => {
      if (hasAspirant.value) {
        return 2;
      }

      return 4;
    });

    const toggleBodyStyle = () => {
      document.body.classList.add(bodyStyleName.value);
    };

    const goToProgram = (id: number) => {
      const scrollToTop = () => window.scrollTo(0, 0);

      if (hasAspirant.value) {
        router.push({ name: RouteName.HOME_ASPIRANT, params: { id } });
        toggleBodyStyle();
        scrollToTop();
      } else if (hasDpo.value) {
        router.push({ name: RouteName.HOME_COURSES, params: { id } });
        toggleBodyStyle();
        scrollToTop();
      }
    };

    const learningTime = (obj: any) => {
      if (obj?.hours) {
        return `${obj?.hours} ак.ч.`;
      } else if (obj?.days) {
        return `${obj?.days} д.`;
      }

      return `${obj?.years} г.`;
    };

    const learningWeeks = (obj: any) => {
      if (obj?.weeks === 1) return `${obj?.weeks} неделя`;
      else if (obj?.weeks > 1 && obj?.weeks < 5) return `${obj?.weeks} недели`;
      else return `${obj?.weeks} недель`;
    };

    const dispatchAllList = async () => {
      await store.dispatch.programsStore.getDegreeList({
        degree_id: degreeId.value,
      });

      programsList.value = degreeList.value;
      pageCount.value = 1;
    };

    const clearFilters = () => {
      dispatchAllList();

      sortValue.value = "";
      sortName.value = "";

      searchValue.value = "";
    };

    const setSortValue = async (value: any, name: any) => {
      if (sortValue.value === value || sortValue.value === "-") {
        sortValue.value === "";
        sortName.value = "";
      } else {
        sortValue.value = value;
        sortName.value = name;
        if (value === "") {
          await dispatchAllList();
        } else {
          await store.dispatch.programsStore.getDegreeList({
            degree_id: degreeId.value,
            hasFilters: true,
            filterName: name,
            filterValue: value,
          });
        }
      }

      programsList.value = degreeList.value;
      pageCount.value = 1;
    };

    const handleSearch = async () => {
      await store.dispatch.programsStore.getDegreeList({
        degree_id: degreeId.value,
        hasFilters: true,
        filterName: "search",
        filterValue: searchValue.value,
      });

      programsList.value = degreeList.value;
      pageCount.value = 1;
    };

    const handleDebounceSearch = debounce(handleSearch, 1000);

    const toggleMore = async () => {
      if (sortName.value !== "") {
        await store.dispatch.programsStore.getDegreeList({
          hasFilters: true,
          filterName: sortName.value,
          filterValue: sortValue.value,
          degree_id: degreeId.value,
          pageNumber: ++pageCount.value,
        });
      } else {
        await store.dispatch.programsStore.getDegreeList({
          degree_id: degreeId.value,
          pageNumber: ++pageCount.value,
        });
      }

      programsList.value = programsList.value.concat(degreeList.value);
    };

    watch(
      () => bodyStyleName.value,
      async () => {
        await document.body.classList.remove("courses", "aspirant", "ordinat");
        toggleBodyStyle();
      }
    );
    watch(
      () => degreeId.value,
      async () => {
        await dispatchAllList();
      }
    );

    document.body.classList.remove("courses", "aspirant", "ordinat");

    onMounted(async () => {
      toggleBodyStyle();

      await dispatchAllList();

      store.dispatch.programsStore.getCategories({ degree_id: degreeId.value });
    });

    return {
      degreeId,
      hasAspirant,
      hasDpo,
      route,
      homeNavList,
      pageCount,
      programsListMeta,
      degreeList,
      programsList,
      searchValue,
      sortList1,
      sortList2,
      sortList3,
      sortList4,
      sortValue,
      hasProgramsList,
      emailsList,
      emailsDpoList,
      phonesList,
      phonesDpoList,
      setSortValue,
      toggleBodyStyle,
      goToProgram,
      learningTime,
      clearFilters,
      learningWeeks,
      toggleMore,
      handleDebounceSearch,
    };
  },
});
</script>

<style lang="stylus" scoped>
.header {
  &__icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
}

.programsSort {
  padding: 20px;
  margin: auto;

  &__itemsBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    &:not(:nth-child(0)) {
      margin-top: 8px;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 24px;
    background-color: $magistracy-light-grey;
    flex-grow: 1;
    text-wrap: nowrap;
    border-radius: 10px;
    cursor: pointer
    font-size: 12px;

    &:hover {
      opacity: .6;
    }

    &_active {
      background-color: $as-green-magistrDpo;
      color: $color-white;
    }

    &_disabled {
      opacity: .6;
      cursor: default;
    }
  }

  &__itemClear {
    background-color: $color-white;
    border: 1px solid $magistracy-light-grey;
  }

  &__search {
    margin-top: 20px;
  }

  &__searchIcon {
    width: 24px;
    height: 24px;
  }

  @media (min-width:1400px) {
    max-width: 1400px;
  }

  +mediaTablet() {
    &__item {
      font-size: 14px;
    }
  }

  +mediaGiant() {
    &__item {
      font-size: 18px;
    }
  }
}

.programsList {
  &Wrap {
    margin: 0 auto;
  }
  padding: 20px;
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(1, 80%);
  justify-content: center;
  gap: 40px;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    justify-self: center;
  }

  &__imgBox {
    width: 100%;
    position: relative;
    border-radius: 24px;
    overflow: hidden;
  }

  &__imgWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#000000CC, #00000000);
    z-index: 2;
  }

  &__img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__tabsList {
    display: flex;
    gap: 11px;
  }

  &__listItem {
    padding: 10px;
    border: 1px solid #E24E25;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 600;
  }
  &__imgContent {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    font-size: 12px;
  }

  &__nmo {
    padding: 10px;
    background-color: $color-white;
    border-radius: 30px;
    color: #E24E25;
    font-weight: 600;
  }

  &__title {
    flex-grow: 1;
  }

  &__btn {
    margin-top: 13px;
    width: 238px;
    text-align: center;
    padding: 14px 0;
    font-weight: 600;
  }

  &__moreButton {
    margin: auto;
    margin-bottom: 170px;
    display: flex;
    gap: 16px;
    background-color: transparent;
    border: none;
    &:hover {
      opacity: .6;
    }
  }

  &__moreText {
    font-size: 24px;
  }

  &__arrowMore {
    width: 33px;
    height: 22px;
    position: relative;
    top: 10px;
  }

  +mediaPhone() {
    &__item {
      width: 420px;
    }

    &__imgBox {
      height: 252px;
    }
  }

  +mediaTablet() {
    &__imgContent, &__listItem {
      font-size: 16px;
    }

    &__moreButton {
      margin: 0;
      margin-bottom: 170px;
    }
  }

  +mediaLarge() {
    grid-template-columns: repeat(2, calc(50% - 20px));
  }

  +mediaGiant() {
    &__imgContent, &__listItem {
      font-size: 18px;
    }
  }
  @media (min-width:1400px) {
    &Wrap {
      max-width: 1400px;
    }
    grid-template-columns: repeat(3, calc(33% - 20px));
  }
}
</style>
