import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonTextEditor = _resolveComponent("CommonTextEditor")!
  const _component_CommonFormContainer = _resolveComponent("CommonFormContainer")!

  return (_openBlock(), _createBlock(_component_CommonFormContainer, { title: "О программе" }, {
    default: _withCtx(() => [
      _createVNode(_component_CommonInput, {
        modelValue: _ctx.formModel.title,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.title) = $event)),
          _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
        ],
        placeholder: "Название"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Название ")
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_CommonTextEditor, {
        modelValue: _ctx.formModel.desc.desc,
        "onUpdate:modelValue": [
          _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.desc.desc) = $event)),
          _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
        ]
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Описание ")
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_CommonInput, {
        modelValue: _ctx.formModel.desc.header,
        "onUpdate:modelValue": [
          _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formModel.desc.header) = $event)),
          _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
        ],
        placeholder: "Текст"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Заголовок ")
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_CommonTextEditor, {
        modelValue: _ctx.formModel.for_whom,
        "onUpdate:modelValue": [
          _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formModel.for_whom) = $event)),
          _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
        ]
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Для кого ")
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}