<template>
  <aside class="side">
    <div class="side__wrap">
      <div class="side__navigationBox">
        <div
          :class="['side__item', { side__itemExpansion: !item.pathName }]"
          v-for="(item, i) in navList"
          :key="item.id"
        >
          <svg class="side__icon" v-if="item.pathName">
            <use
              :href="`#side-icon${
                hasActiveRoute(item).value ? '-active' : ''
              }-${i + 1}--sprite`"
            />
          </svg>

          <div class="side__title" v-if="item.pathName">
            <router-link class="side__link" :to="{ name: item.pathName }">
              <span :class="{ side__label_active: hasActiveRoute(item).value }">
                {{ item.label }}
              </span>
            </router-link>
          </div>
          <v-expansion-panels v-else>
            <v-expansion-panel class="side__expansionPanel">
              <v-expansion-panel-title class="side__expansionPanelTitle">
                <svg class="side__icon">
                  <use
                    :href="`#side-icon${
                      hasActiveRoute(item, true).value ? '-active' : ''
                    }-${i + 1}--sprite`"
                  />
                </svg>
                <span
                  :class="{
                    side__label_active: hasActiveRoute(item, true).value,
                  }"
                >
                  {{ item.label }}
                </span>
              </v-expansion-panel-title>

              <v-expansion-panel-text
                v-for="subnav in item.subNav"
                :key="subnav.id"
                class="side__expansionPanelText"
              >
                <router-link class="side__link" :to="{ name: subnav.pathName }">
                  <span
                    :class="{
                      side__label_active: hasActiveRoute(subnav).value,
                    }"
                  >
                    {{ subnav.label }}
                  </span>
                </router-link>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>

      <div class="side__actionsBox">
        <div class="side__item" v-for="(item, i) in actionsList" :key="item.id">
          <svg class="side__icon">
            <use :href="`#actions-icon-${i + 1}--sprite`" />
          </svg>

          <div class="side__title">{{ item.label }}</div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { navList, actionsList } from "./constants";
import { useRoute } from "vue-router";

import "@/assets/img/icons/side-icon-1.svg?sprite";
import "@/assets/img/icons/side-icon-2.svg?sprite";
import "@/assets/img/icons/side-icon-3.svg?sprite";
import "@/assets/img/icons/side-icon-4.svg?sprite";
import "@/assets/img/icons/side-icon-5.svg?sprite";
import "@/assets/img/icons/side-icon-6.svg?sprite";
import "@/assets/img/icons/side-icon-active-1.svg?sprite";
import "@/assets/img/icons/side-icon-active-2.svg?sprite";
import "@/assets/img/icons/side-icon-active-3.svg?sprite";
import "@/assets/img/icons/side-icon-active-4.svg?sprite";
import "@/assets/img/icons/side-icon-active-5.svg?sprite";
import "@/assets/img/icons/side-icon-active-6.svg?sprite";
import "@/assets/img/icons/actions-icon-1.svg?sprite";
import "@/assets/img/icons/actions-icon-2.svg?sprite";
import { computed } from "vue";

export default defineComponent({
  name: "LayoutSide",
  components: {},

  setup() {
    const route = useRoute();

    const hasActiveRoute = (item: any, hasSubnav = false) =>
      computed(() => {
        if (hasSubnav) {
          for (let i = 0; i < item.subNav.length; i++) {
            if (item.subNav[i].pathName === route.name) {
              return true;
            }
          }
          return false;
        }

        return route.name === item.pathName;
      });

    return {
      navList,
      actionsList,
      hasActiveRoute,
    };
  },
});
</script>

<style lang="stylus" scoped>
a {
  font-size: 16px;
}
::v-deep {
  .v-expansion-panel__shadow {
    box-shadow: none;
  }

  .v-expansion-panel-title {
    padding: 0;
  }

  .v-expansion-panel-title__overlay {
    &:hover {
      background-color: $magistracy-light-grey;
      opacity: 1;
    }
  }

  .v-expansion-panel-text__wrapper {
    getFontSmall();
  }
}

.side {
  position: sticky;
  width: 290px;
  padding: 22px;
  background-color: $color-white;
  border-right: 1px solid $magistracy-medium-grey;

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-height: 800px;
  }

  &__navigationBox,
  &__actionsBox {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__expansionPanel {
    min-height: 24px;
  }

  &__expansionPanelTitle {
    display: flex;
    align-items: center;
    height: 24px;
    min-height: 24px;
    gap: 16px;
    font-size: 16px;
    &:hover {
      background-color: $magistracy-light-grey;
    }
  }
  &__expansionPanelText {
    &:hover {
      background-color: $magistracy-light-grey;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 24px;
    min-height: 24px;
    &:hover {
      background-color: $magistracy-light-grey;
      cursor: pointer;
    }
  }

  &__itemExpansion {
    height: auto;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__link {
    text-decoration: none;
  }

  &__label {
    &_active {
      color: $as-active-green;
    }
  }
}
</style>
