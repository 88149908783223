export const statuses = {
  active: {
    color: "#358E42",
    title: "Активная",
  },
  archive: {
    color: "#E24E25",
    title: "В архиве",
  },
  postponed: {
    color: "#E37806",
    title: "Отложена",
  },
};

export const listHeader = [
  "Название",
  "Дата начала",
  "Дата окончания",
  "Кол-во групп",
  "Преподаватели",
  "Статус ",
  "Действия",
];

export const listBody = [
  [
    {
      title: "Математическое и компьютерное моделирование",
    },
    {
      title: "10.01.2024",
    },
    {
      title: "01.06.2024",
    },
    {
      title: "3",
    },
    {
      title: "Иванов В. А., Петров И. Г.",
    },
    {
      title: statuses.active,
      hasStatus: true,
      statusColor: statuses.active.color,
    },
    {
      title: "active",
      hasAction: true,
    },
  ],
];
