export enum Statuses {
  INIT = "INIT",
  PENDING = "PENDING",
  FULFILLED = "FULFILLED",
  REJECTED = "REJECTED",
}

export enum curatorStatuses {
  ACTIVE = "ACTIVE",
  ARCHIVE = "ARCHIVE",
  POSTPONED = "POSTPONED",
}
