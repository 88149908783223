import { createVfm } from "vue-final-modal";
import { vMaska } from "maska";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import { createApp } from "vue";
// import { Statuses } from "@/constants/statuses";
import App from "./App.vue";
import { router } from "./router";
// import store from "./store";
import cors from "cors";
import { createPinia } from "pinia";
import FloatingVue from "floating-vue";
import VCalendar from "v-calendar";
import Toast from "vue-toastification";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi";

import "vue-toastification/dist/index.css";
import "v-calendar/dist/style.css";
import "floating-vue/dist/style.css";
import "vuetify/styles";

import "@mdi/font/css/materialdesignicons.css";

const vfm = createVfm();

const store = createPinia();

const icons = {
  defaultSet: "mdi",
  aliases,
  sets: {
    mdi,
  },
};

const vuetify = createVuetify({
  components,
  icons,
  directives,
  locale: {
    locale: "ru",
  },
});

dayjs.extend(duration);

export const vueApp = createApp(App)
  .use(router)
  .use(vfm)
  .use(FloatingVue)
  .use(VCalendar)
  .use(Toast)
  .use(vuetify);

vueApp.directive("maska", vMaska);

// vueApp.config.globalProperties = {
//   $status: {
//     INIT: Statuses.INIT,
//     PENDING: Statuses.PENDING,
//     FULFILLED: Statuses.FULFILLED,
//     REJECTED: Statuses.REJECTED,
//   },
// };

vueApp.mount("#app");
