export const firstStats = [
  {
    label: "Активные<br />программы",
    value: "3",
  },
  {
    label: "Студенты<br />в группах",
    value: "50",
  },
  {
    label: "Архивные<br />программы",
    value: "2",
  },
];

export const secondStats = [
  {
    label: "оценённые студенты",
    value: "224",
  },
  {
    label: "контент на модерации",
    value: "16",
  },
];
