<template>
  <LayoutBase v-if="hasGeneralPage">
    <div class="generalPage__wrap">
      <MainContainer class="generalPage__main" />

      <NotificationsContainer class="generalPage__notifications" />
    </div>
  </LayoutBase>

  <router-view v-else />
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { MainContainer } from "./components/MainContainer";
import { NotificationsContainer } from "./components/NotificationsContainer";
import { LayoutBase } from "@/layouts/NewLayouts/LayoutBase";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";

export default defineComponent({
  name: "GeneralPage",

  components: {
    LayoutBase,
    MainContainer,
    NotificationsContainer,
  },

  setup() {
    const route = useRoute();

    const hasGeneralPage = computed(
      () => route.name === RouteName.CURATOR_GENERAL
    );

    return {
      hasGeneralPage,
    };
  },
});
</script>

<style lang="stylus" scoped>
.generalPage {
  &__wrap {
    padding: 20px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  &__main {
    flex-grow: 1;
  }

  &__notifications {
    flex-grow: 1;
  }
}
</style>
