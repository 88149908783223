import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6ee44145"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "programs-date-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonDatePicker = _resolveComponent("CommonDatePicker")!
  const _component_CommonSelect = _resolveComponent("CommonSelect")!
  const _component_CommonFormContainer = _resolveComponent("CommonFormContainer")!

  return (_openBlock(), _createBlock(_component_CommonFormContainer, { title: "Дата и статус" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CommonDatePicker, {
          modelValue: _ctx.formModel.start_at,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.start_at) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
          ],
          class: "programs-date",
          label: "Дата старта",
          placeholder: "дд/мм/гггг"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_CommonDatePicker, {
          modelValue: _ctx.formModel.end_at,
          "onUpdate:modelValue": [
            _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.end_at) = $event)),
            _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
          ],
          class: "programs-date",
          label: "Дата окончания",
          placeholder: "дд/мм/гггг"
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_CommonSelect, {
        modelValue: _ctx.formModel.status,
        "onUpdate:modelValue": [
          _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formModel.status) = $event)),
          _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
        ],
        options: ['Активная', 'В архиве', 'Отложена'],
        placeholder: "Выберите из списка",
        multiple: false
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Тип ")
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}