import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from './icons/Vector.svg'


const _withScopeId = (n: any) => (_pushScopeId("data-v-394dcf88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "commonCheckbox" }
const _hoisted_2 = ["checked"]
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  class: "commonCheckbox__box__icon",
  alt: "Checked"
}
const _hoisted_4 = { class: "commonCheckbox__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      class: "commonCheckbox__input",
      checked: _ctx.modelValue,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCheckbox && _ctx.toggleCheckbox(...args)))
    }, null, 40, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["commonCheckbox__box", { 'commonCheckbox__box-checked': _ctx.modelValue }])
    }, [
      (_ctx.modelValue)
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("span", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ], true)
    ])
  ]))
}