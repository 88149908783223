<template>
  <CommonFormContainer title="О программе">
    <CommonInput
      v-model="formModel.title"
      placeholder="Название"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Название
    </CommonInput>

    <CommonTextEditor
      v-model="formModel.desc.desc"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Описание
    </CommonTextEditor>

    <CommonInput
      v-model="formModel.desc.header"
      placeholder="Текст"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Заголовок
    </CommonInput>

    <CommonTextEditor
      v-model="formModel.for_whom"
      @update:model-value="$emit('updateValues', formModel)"
    >
      Для кого
    </CommonTextEditor>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonTextEditor } from "@/components/NewComponents/CommonTextEditor";

export default defineComponent({
  name: "FormAbout",

  emits: ["updateValues"],

  props: ["form"],

  components: {
    CommonFormContainer,
    CommonInput,
    CommonTextEditor,
  },

  setup(props) {
    const formModel = ref({
      title: "",
      desc: {
        header: "",
        desc: "",
      },
      for_whom: "",
    });

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel.value, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
    };
  },
});
</script>

<style lang="stylus" scoped>
.programsPage {
  &__pagination {
    border-top: 1px solid $magistracy-medium-grey;
  }
}
</style>
