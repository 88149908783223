<template>
  <div>
    <v-menu
      v-model="menu"
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template #activator="{ props }">
        <CommonInput
          v-bind="props"
          :model-value="formattedDate"
          :label="label"
          :placeholder="placeholder"
          :background-color="backgroundColor"
          readonly
          height="44px"
          @click:append-inner="menu = true"
        >
          <template #suffix><img src="./icons/calendar.svg" /></template>
        </CommonInput>
      </template>

      <div class="commonDate">
        <v-date-picker
          v-model="internalDate"
          show-adjacent-months
          locale="ru"
          color="#358E42"
          first-day-of-week="1"
          :next-icon="nextIcon"
          :prev-icon="prevIcon"
          :mode-icon="downIcon"
        >
        </v-date-picker>

        <div class="commonDate__control">
          <CommonButton
            color-type="green-light"
            label="Применить"
            @click="applyDate"
          />
          <CommonButton
            color-type="green-light"
            outline
            label="Сбросить"
            @click="resetDate"
          />
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import { CommonInput } from "../CommonInput";
import { CommonButton } from "../CommonButton";

import nextIcon from "./icons/CustomNext.vue";
import prevIcon from "./icons/CustomPrev.vue";
import downIcon from "./icons/CustomDown.vue";

export default defineComponent({
  name: "DatePicker",

  components: {
    CommonInput,
    CommonButton,
  },

  props: {
    modelValue: {
      type: [String, Date],
      default: null,
    },
    label: String,
    placeholder: String,
    backgroundColor: String,
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const menu = ref(false);
    const internalDate = ref<Date | null>(
      props.modelValue ? new Date(props.modelValue) : null
    );

    watch(
      () => props.modelValue,
      (newVal) => {
        internalDate.value = newVal ? new Date(newVal) : null;
      }
    );

    const formattedDate = computed(() => {
      if (!internalDate.value) return "";
      return internalDate.value.toLocaleDateString("ru-RU");
    });

    const applyDate = () => {
      if (!internalDate.value) {
        internalDate.value = new Date();
        emit(
          "update:modelValue",
          internalDate.value.toISOString().split("T")[0]
        );
      }
      menu.value = false;
    };

    const resetDate = () => {
      internalDate.value = new Date();
      emit("update:modelValue", internalDate.value.toISOString().split("T")[0]);
    };

    return {
      menu,
      internalDate,
      formattedDate,
      applyDate,
      resetDate,

      nextIcon,
      prevIcon,
      downIcon,
    };
  },
});
</script>

<style scoped lang="stylus">
.commonDate {
  background: #fff;
  border-radius: 10px;
  width: 340px;
  padding: 12px 6px 24px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1),
  0px 2px 4px rgba(0, 0, 0, 0.06);

  &__control {
    display: flex;
    column-gap: 16px;
    padding: 0 18px;
  }
}

::v-deep {
  .v-date-picker-month__day .v-btn.v-date-picker-month__day-btn {
    border-radius: 10px !important;
  }

  .v-picker-title {
    display: none;
  }

  .v-picker__header {
    display: none;
  }

  .v-date-picker-controls__month-btn {
    font-weight: 600 !important;
    getFontSmall();
    text-transform: capitalize !important;
  }
}
</style>
