<template>
  <img src="./next.svg" class="custom-icon" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomNext",
});
</script>

<style scoped>
.custom-icon {
  width: 20px;
  height: 20px;
}
</style>
