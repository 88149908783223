<template>
  <footer class="footer">
    <div class="footer__links">
      <a class="footer__link" href="#" v-for="link in links" :key="link">
        {{ link }}
      </a>
    </div>

    <div class="footer__socials">
      <a class="footer__socialItem" href="">
        <svg class="footer__socialItem">
          <use xlink:href="#vk-icon--sprite" />
        </svg>
      </a>

      <a class="footer__socialItem" href="">
        <svg class="footer__socialItem">
          <use xlink:href="#dzen-icon--sprite" />
        </svg>
      </a>

      <a class="footer__socialItem" href="">
        <svg class="footer__socialItem">
          <use xlink:href="#yt-icon--sprite" />
        </svg>
      </a>

      <a class="footer__socialItem" href="">
        <svg class="footer__socialItem">
          <use xlink:href="#tg-icon--sprite" />
        </svg>
      </a>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { links } from "./constants";

import "@/assets/img/icons/vk-icon.svg?sprite";
import "@/assets/img/icons/dzen-icon.svg?sprite";
import "@/assets/img/icons/yt-icon.svg?sprite";
import "@/assets/img/icons/tg-icon.svg?sprite";

export default defineComponent({
  name: "LayoutFooter",
  components: {},

  setup() {
    return {
      links,
    };
  },
});
</script>

<style lang="stylus" scoped>
.footer {
  padding: 24px 29px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $magistracy-medium-grey;
  border-radius: 10px;
  background-color: $color-white;

  &__links {
    display: flex;
    align-items: center;
    gap: 40px;
  }

  &__link {
    text-decoration: none;
  }

  &__socials {
    display: flex;
    gap: 16px;
  }

  &__socialItem {
    width: 28px;
    height: 28px;
  }
}
</style>
