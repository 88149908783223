<template>
  <CommonFormContainer title="FAQ">
    <div
      class="programs-input-box"
      v-for="(item, i) in formModel"
      :key="`how-learn-going-${i}`"
    >
      <CommonInput
        v-model="formModel[i].question"
        placeholder="Вопрос"
        @update:model-value="$emit('updateValues', formModel)"
      >
        Вопрос
      </CommonInput>

      <CommonTextEditor
        v-model="formModel[i].answer"
        @update:model-value="$emit('updateValues', formModel)"
      >
        Ответ
      </CommonTextEditor>

      <div
        class="programs-delete"
        @click="
          $emit('updateValues', formModel);
          removeItem(i);
        "
      >
        Удалить
      </div>
    </div>

    <svg
      class="programs-plus"
      @click="
        pushNewItem();
        $emit('updateValues', formModel);
      "
    >
      <use xlink:href="#plus-curator--sprite" />
    </svg>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonTextEditor } from "@/components/NewComponents/CommonTextEditor";

export default defineComponent({
  name: "FormFaq",

  components: {
    CommonFormContainer,
    CommonInput,
    CommonTextEditor,
  },

  props: ["form"],

  setup(props) {
    const formModel = reactive([
      {
        question: "",
        answer: "",
      },
    ]);

    const pushNewItem = () => {
      formModel.push({
        question: "",
        answer: "",
      });
    };

    const removeItem = (index: number) => {
      formModel.splice(index, 1);
    };

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
      pushNewItem,
      removeItem,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
